import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {PageRequest, PageResponse} from '../domain/common/paging';
import {FilterField} from '../domain/common/search/filter-field';
import {BuildInfo} from '../domain/common/buildinfo';
import {TimeUnit} from '../domain/forms/timeunit';
import {BrandLocationRequest} from '../domain/generated/offers/model/brandLocationRequest';
import {PageOfUser} from '../domain/generated/offers/model/pageOfUser';
import {User} from '../domain/generated/offers/model/user';
import {Campaign} from '../domain/generated/offers/model/campaign';
import {PageOfUnspentFundsResponse} from '../domain/generated/offers/model/pageOfUnspentFundsResponse';
import {Merchant} from '../domain/generated/offers/model/merchant';
import {MerchantRequest} from '../domain/generated/offers/model/merchantRequest';
import {PageOfMerchant} from '../domain/generated/offers/model/pageOfMerchant';
import {PageOfBrandLocation} from '../domain/generated/offers/model/pageOfBrandLocation';
import {BrandLocation} from '../domain/generated/offers/model/brandLocation';
import {UpdateBrandLocationRequest} from '../domain/generated/offers/model/updateBrandLocationRequest';
import {RewardCampaignRequest} from '../domain/generated/offers/model/rewardCampaignRequest';
import {RewardCampaign} from '../domain/generated/offers/model/rewardCampaign';
import {PageOfRewardCampaign} from '../domain/generated/offers/model/pageOfRewardCampaign';
import {RewardCampaignUpdateRequest} from '../domain/generated/offers/model/rewardCampaignUpdateRequest';
import {RewardCampaignMediaRequest} from '../domain/generated/offers/model/rewardCampaignMediaRequest';
import {RewardCampaignMediaResponse} from '../domain/generated/offers/model/rewardCampaignMediaResponse';
import {RewardProgram} from '../domain/generated/offers/model/rewardProgram';
import {PageOfRewardProgram} from '../domain/generated/offers/model/pageOfRewardProgram';
import {RewardProgramUpdateRequest} from '../domain/generated/offers/model/rewardProgramUpdateRequest';
import {RewardProgramRequest} from '../domain/generated/offers/model/rewardProgramRequest';
import {PageOfRewardActivityTotalsView} from '../domain/generated/offers/model/pageOfRewardActivityTotalsView';
import {RewardActivityMultiTotals} from '../domain/generated/offers/model/rewardActivityMultiTotals';
import {RewardProgramFundsRequest} from '../domain/generated/offers/model/rewardProgramFundsRequest';
import {RewardProgramFundLogResponse} from '../domain/generated/offers/model/rewardProgramFundLogResponse';
import {
  PageOfRewardProgramFundLogResponse
} from '../domain/generated/offers/model/pageOfRewardProgramFundLogResponse';
import {RewardsQualifyingTransaction} from '../domain/generated/offers/model/rewardsQualifyingTransaction';
import {PageOfQualifyingTransaction} from '../domain/generated/offers/model/pageOfQualifyingTransaction';
import {PageOfRewardsSubscriber} from '../domain/generated/offers/model/pageOfRewardsSubscriber';
import {Transaction} from '../domain/generated/offers/model/transaction';
import {PageOfClosedLoopProgram} from '../domain/generated/offers/model/pageOfClosedLoopProgram';
import {ClosedLoopProgram} from '../domain/generated/offers/model/closedLoopProgram';
import {ClosedLoopProgramRequest} from '../domain/generated/offers/model/closedLoopProgramRequest';
import {ClosedLoopProgramUpdateRequest} from '../domain/generated/offers/model/closedLoopProgramUpdateRequest';
import {Activity} from '../domain/generated/offers/model/activity';
import {PageOfActivity} from '../domain/generated/offers/model/pageOfActivity';
import {FeedItem} from '../domain/generated/offers/model/feedItem';
import {UpdateFundsRequest} from '../domain/generated/offers/model/updateFundsRequest';
import {RewardTransaction} from '../domain/generated/offers/model/rewardTransaction';

@Injectable()
export class OffersService  extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  getBuildInfo() {
    return this.http.get<BuildInfo>(`${environment.offersUrl}/build`);
  }

  getUnspentBalances(pageRequest: PageRequest): Observable<PageOfUnspentFundsResponse> {
    const searchParams: HttpParams = new HttpParams()
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);
    const url = `${environment.offersUrl}/admin/fund`;
    return this.http.get<PageOfUnspentFundsResponse>(url, {params: searchParams})
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getLoans(userUuid: string): Observable<any> {
    return this.http.get<any>(environment.offersUrl + '/loan')
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getOffers(userUuid: string): Observable<any> {
    return this.http.get<any>(environment.offersUrl + '/offer')
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  uploadImageToPresignedUrl(url: string, image: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('uploadFile', image, image.name);
    return this.http.put(url, image)
  }

  /*
   * Activity
   * */

  getActivity(uuid: string): Observable<Activity> {
    return this.http.get<Activity>(`${environment.offersUrl}/admin/activity/${uuid}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  getActivities(pageRequest: PageRequest,
                filterParams: FilterField[]): Observable<PageOfActivity> {
    let searchParams: HttpParams = new HttpParams()
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    filterParams.forEach(filter => {
      if (filter.value) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });
    return this.http.get<PageOfActivity>(
      environment.offersUrl + '/admin/activity',
      {params: searchParams}
    ).pipe(
        catchError(this.handleError)
      );
  }

  getActivityFeed(): Observable<FeedItem[]>  {
    const url = environment.offersUrl + '/feed';
    return this.http.get<any>(url)
      .pipe(
        // map(response => this.processFeedItems(response)),
        catchError(this.handleError)
      )
  }

  // private formatActivityResponse(body: any) {
  //   if (body) {
  //     body.forEach(item => {
  //       item.created = new Date(item.created).toLocaleString();
  //     });
  //   }
  //   return JSON.stringify(body, null, 2);
  // }

  // private processFeedItems(response: any) {
  //   const items: FeedItem[] = [];
  //   response.forEach(responseItem => {
  //     const item: FeedItem = {};
  //     item.timestamp = responseItem.timestamp;
  //     item.description = responseItem.description;
  //     item.type = responseItem.type;
  //     // item.userName = responseItem.feedName;
  //     // item.apr = responseItem.loanApr;
  //     // item.paymentMonths = responseItem.paymentMonths;
  //     // item.merchantName = responseItem.merchantName;
  //     // item.merchantUuid = responseItem.merchantUuid;
  //
  //     // FIXME dont use as any
  //     switch (responseItem.type.toLowerCase()) {
  //       case 'spend':
  //         (item as any).activityType = ActivityType.SPEND;
  //         break;
  //       case 'payment':
  //         (item as any).activityType = ActivityType.RECEIVE;
  //         break;
  //       case 'receive':
  //         (item as any).activityType = ActivityType.RECEIVE_GIFT;
  //         break;
  //     }
  //     items.push(item);
  //
  //   });
  //   return items;
  // }

  /*
   * Funds
   */

  getFunds(userUuid: string): Observable<any> {
    const url = `${environment.offersUrl}/admin/fund/${userUuid}`;
    return this.http.get<any>(`${environment.offersUrl}/admin/fund/${userUuid}`)
      .pipe(
        map(funds => {
          const real_balance = (Number(funds.balance) - Number(funds.cash_drawer_balance)).toString();
          return {
            balance: real_balance,
            cash_drawer_balance: funds.cash_drawer_balance,
            pending_balance: funds.pending_balance,
            giftCards: funds.giftCards,
            additional_balance_to_provision: funds.additional_balance_to_provision,
            total_balance: funds.total_balance,
            provision_threshold: funds.provision_threshold
          };
        }),
        catchError(this.handleError)
      )
  }

  updateFunds(userUuid: string,
              amount: string,
              memo: string,
              internalNote: string) {
    const body: UpdateFundsRequest = {
      amount,
      internalNote: (internalNote != null && internalNote.length > 0) ? internalNote : null,
      memo: (memo != null && memo.length > 0) ? memo : null,
      cashAdjustment: true
    };

    return this.http.put<any>(
      `${environment.offersUrl}/admin/fund/${userUuid}`,
      body
    ).pipe(catchError(this.handleError))
  }

  /*
  * User
  * */

  getOffersUserPage(pageRequest: PageRequest, filters: FilterField[] = []): Observable<PageOfUser> {
    let searchParams: HttpParams = new HttpParams()
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    filters.forEach(filter => {
      if (filter.value != null) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });

    const url = `${environment.offersUrl}/user/search`;
    return this.http.get<PageOfUser>(url, {params: searchParams})
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getOffersUser(userUuid: string): Observable<User> {
    return this.http.get<User>(`${environment.offersUrl}/admin/user/${userUuid}`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  createOffersUser(userUuid: string): Observable<User> {
    const body = {};
    return this.http.post<User>(`${environment.offersUrl}/admin/user/${userUuid}`, body)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  setHold(uuid: string, hold: boolean, holdReason: string = null) {
    const body = {hold, holdReason: (hold ? holdReason : null)};
    return this.http.put<User>(`${environment.offersUrl}/admin/user/${uuid}`, body);
  }

  setCreditLimit(uuid: string, creditLimit: boolean) {
    const body = {creditLimit};
    return this.http.put<User>(`${environment.offersUrl}/admin/user/${uuid}`, body);
  }

  deleteOffersUser(uuid: string): Observable<any> {
    return this.http.delete(`${environment.offersUrl}/admin/user/${uuid}`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  addTestAccount(email: string): Observable<any> {
    const body = {email};
    return this.http.post(`${environment.offersUrl}/admin/user/testaccount`, body)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getAllTestAccounts(pageRequest: PageRequest): Observable<PageOfUser> {
    const searchParams: HttpParams = new HttpParams()
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    return this.http.get<PageOfUser>(`${environment.offersUrl}/admin/user/testaccounts`, {params: searchParams})
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  removeTestAccount(userUuid: string): Observable<any> {
    return this.http.delete<PageResponse<any>>(`${environment.offersUrl}/admin/user/${userUuid}/testaccount`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  /*
  * Campaigns
  * */

  private campaignMapper(campaign: Campaign): Campaign {
    console.log(campaign);
    (campaign as any).percentBased = (campaign.percentOff?.length > 0);
    (campaign as any).discount = ((campaign as any).percentBased) ? campaign.percentOff : campaign.dollarOff;
    (campaign as any).durationUnit = TimeUnit.hour;
    (campaign as any).durationTime = campaign.durationHours;
    (campaign as any).collectionLimited = !(campaign.collectLimit === 0);
    (campaign as any).feedCollectionLimited = !(campaign.feedCollectLimit === 0);
    campaign.collectLimit = (campaign.collectLimit === -1) ? 0 : campaign.collectLimit;
    campaign.feedCollectLimit = (campaign.feedCollectLimit === -1) ? 0 : campaign.feedCollectLimit;
    (campaign as any).isTimeBasedOffer = (campaign.weekdays && campaign.weekdays.length > 0) ||
      (campaign.monthdays && campaign.monthdays.length > 0) ||
      (campaign.months && campaign.months.length > 0) ||
      (campaign.startTime && (campaign as any).startTime.length > 0) ||
      (campaign.endTime && (campaign as any).endTime.length > 0);

    (campaign as any).monthdaysDates = campaign.monthdays && campaign.monthdays.length > 0 ? campaign.monthdays.map(it => {
      const date = new Date();
      date.setDate(+it);
      date.setFullYear(2017);
      date.setMonth(0);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date;
    }) : null;

    (campaign as any).startTimeMapped = campaign.startTime ? {
      hour: campaign.startTime.hours,
      minute: campaign.startTime.minutes
    } : null;

    (campaign as any).endTimeMapped = campaign.endTime ? {
      hour: campaign.endTime.hours,
      minute: campaign.endTime.minutes
    } : null;

    return campaign;
  }

  save(campaign: Campaign): Observable<Campaign> {
    const body = {
      merchantUuid: campaign.merchant.uuid,
      description: campaign.description,
      dollarOff: !((campaign as any).percentBased) ? (campaign as any).discount : '',
      percentOff: (campaign as any).percentBased ? (campaign as any).discount : '',
      durationHours: TimeUnit.toHours((campaign as any).durationTime, (campaign as any).durationUnit),
      saveUpTo: (campaign as any).percentBased ? campaign.saveUpTo : (campaign as any).discount,
      whenSpend: campaign.whenSpend,
      feedCollectLimit: !((campaign as any).feedCollectionLimited) ? 0 : campaign.feedCollectLimit === 0 ? -1 : campaign.feedCollectLimit,
      collectLimit: !((campaign as any).collectionLimited) ? 0 : campaign.collectLimit === 0 ? -1 : campaign.collectLimit,
      recollect: campaign.recollect,
      kycRequired: campaign.kycRequired,
      onKyc: campaign.onKyc,
      purchases: campaign.purchases,
      purchasesAtLeast: campaign.purchasesAtLeast,
      visits: campaign.visits,
      totalSpend: campaign.totalSpend,
      loyaltyPercentOff: campaign.loyaltyPercentOff,
      loyaltyDollarOff: campaign.loyaltyDollarOff,
      maxValue: campaign.maxValue,
      timeZone: campaign.timeZone,
      weekdays: (campaign as any).isTimeBasedOffer && campaign.weekdays ? campaign.weekdays : [],
      monthdays: (campaign as any).monthdaysDates ? (campaign as any).monthdaysDates.map(it => it.getDate()) : [],
      months: (campaign as any).isTimeBasedOffer && campaign.months ? campaign.months : [],
      startTime: (campaign as any).isTimeBasedOffer && (campaign as any).startTimeMapped && (campaign as any).startTimeMapped.hour
        ? (campaign as any).startTimeMapped.hour + ':' + (
          (campaign as any).startTimeMapped.minute
            ? (campaign as any).startTimeMapped.minute
            : '00'
          ) + ':00'
        : '',
      endTime: (campaign as any).isTimeBasedOffer && (campaign as any).endTimeMapped && (campaign as any).endTimeMapped.hour
        ? (campaign as any).endTimeMapped.hour + ':' + (
          (campaign as any).endTimeMapped.minute
            ? (campaign as any).endTimeMapped.minute
            : '00'
          ) + ':00'
        : '',
      offerUrl: campaign.offerUrl,
      // 'credit':  isAdmin ? campaign.credit : null,
      // 'apr':  isAdmin ? campaign.apr : null,
    };

    if (campaign.id && campaign.id !== 0) {
      return this.http.put<Campaign>(
        environment.offersUrl + '/admin/campaign/' + campaign.id,
        body
      );
      // .map(updatedCampaign => this.updateCache(this.campaignMapper(updatedCampaign), this.campaignCache));
    } else {
      return this.http.post<Campaign>(
        environment.offersUrl + '/admin/campaign',
        body
      );
      // .map(updatedCampaign => {
      // this.updateCache(this.campaignMapper(updatedCampaign), this.campaignCache);
      // this.updateMerchant(updatedCampaign, this.merchantService.merchantCache);
      // return updatedCampaign;
      // });
    }
  }

  getCampaigns(merchantUuid: string = null): Observable<Array<Campaign>> {
    return this.http.get<Array<Campaign>>(environment.offersUrl + '/admin/campaign' + (merchantUuid ? '?merchant=' + merchantUuid : '' )).pipe(
      map(campaigns => {
        campaigns.forEach(it => this.campaignMapper(it));
        return campaigns;
      })
    );
  }

  /*
  * Merchants
  * */

  getMerchant(uuid: string): Observable<Merchant> {
    return this.http.get<Merchant>(environment.offersUrl + '/admin/merchant/' + uuid );
    //   .pipe(
    //   map(merchant => {
    //     // merchants.forEach(it => {
    //     //   this.merchantMapper(it);
    //     // });
    //     return this.merchantMapper(merchant);
    //   })
    // );
  }

  deleteMerchant(uuid: string): Observable<any> {
    return this.http.delete<any>(`${environment.offersUrl}/admin/merchant/${uuid}/locations`);
  }

  // private merchantMapper(merchant: OffersMerchant) {
  //   if (merchant.merchantIdentifier) {
  //     const paddedId = merchant.merchantIdentifier.padEnd(40, ' ').toUpperCase();
  //     merchant.identifier = paddedId.substring(19, 38).trim();
  //     merchant.city = paddedId.substring(3, 19).replace(/\./g, ' ').trim();
  //     merchant.state = paddedId.substring(0, 3).replace(/\./g, ' ').trim();
  //   }
  //   // if (!merchant.keywords || merchant.keywords.length === 0) { merchant.keywordsArray = []; } else {
  //   //   merchant.keywordsArray = merchant.keywords.split(',');
  //   // }
  //   return merchant;
  // }

  private formatMerchantId(city: string, state: string, identifier: string) {
    if (!city && !state && !identifier) {
      return null;
    }
    const cityFormatted = (!city) || (city.length === 0) ? ('').padEnd(16, '.') : city.padEnd(16, '.');
    const stateFormatted = (((!state) || state.toUpperCase() === 'ALL') ? '...' : state.padEnd(3, ' '));
    return (stateFormatted + cityFormatted + identifier.padEnd(19, ' ')).toUpperCase();
  }

  createBrand(request: MerchantRequest): Observable<Merchant> {
    request['type'] = MerchantRequest.TypeEnum.Brand;

    return this.http.post<Merchant>(`${environment.offersUrl}/admin/merchant`, request);
    //   .pipe(
    //   map( merchant => {
    //       return this.merchantMapper(merchant);
    //     }
    //   )
    // );
  }

  createMid(request: MerchantRequest): Observable<Merchant> {
    request['type'] = MerchantRequest.TypeEnum.Mid;

    return this.http.post<Merchant>(`${environment.offersUrl}/admin/merchant`, request);
    //   .pipe(
    //   map(merchant => {
    //       return this.merchantMapper(merchant);
    //     }
    //   )
    // );
  }

  getMerchants(pageRequest: PageRequest = null,
               filters: Record<string, any> = null,
               unpaged: boolean = false): Observable<PageOfMerchant> {
    let searchParams: HttpParams = new HttpParams()
      .append('sort', `updated,created,desc`);

    if (unpaged) {
      searchParams = searchParams.append('unpaged', `${unpaged}`);
    } else {
      searchParams = searchParams.append('page', `${pageRequest.page}`);
      searchParams = searchParams.append('size', `${pageRequest.size}`);
    }

    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        if (key === 'uuid') {
          searchParams = searchParams.append('brandId', filters[key]);
        } else {
          searchParams = searchParams.append(key, filters[key]);
        }
      }
    });

    return this.http.get<PageOfMerchant>(environment.offersUrl + `/admin/merchant`, {params: searchParams});
    //   .pipe(
    //   map(result => {
    //     result.content.forEach(it => {
    //       this.merchantMapper(it);
    //     });
    //     return result;
    //   })
    // );
  }

  updateMerchant(uuid: string, request: MerchantRequest): Observable<Merchant> {
    return this.http.put<Merchant>(`${environment.offersUrl}/admin/merchant/${uuid}`, request);
    //   .pipe(
    //   map( merchant => {
    //       return this.merchantMapper(merchant);
    //     }
    //   )
    // );
  }

  // createDollarOffCampaign(merchantUuid: string, description: string, owner: string, identifier: string, city: string, state: string, giftOption: boolean, verified: boolean): Observable<OffersMerchant> {
  //   const body = {
  //     description,
  //     owner,
  //     name,
  //     giftOption,
  //     verified,
  //   };
  //   return this.http.post<OffersMerchant>(`${environment.offersUrl}/admin/merchant`, body).pipe(
  //     map( merchant => {
  //         return this.merchantMapper(merchant);
  //       }
  //     )
  //   );
  // }

  getBrandLocations(brandId: string, pageRequest: PageRequest): Observable<PageOfBrandLocation> {
    const searchParams: HttpParams = new HttpParams()
      .append('brandId', `${brandId}`)
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    return this.http.get<PageOfBrandLocation>(environment.offersUrl + `/admin/merchant/locations`, {params: searchParams});
  }

  createBrandLocation(request: BrandLocationRequest): Observable<BrandLocation> {
    return this.http.post<BrandLocation>(`${environment.offersUrl}/admin/merchant/locations`, request);
  }

  updateBrandLocations(brandLocationId: string, request: UpdateBrandLocationRequest): Observable<BrandLocation> {
    return this.http.put<BrandLocation>(`${environment.offersUrl}/admin/merchant/locations/${brandLocationId}`, request);
  }

  /*
  * Reward Campaigns
  * */

  createRewardCampaign(request: RewardCampaignRequest): Observable<RewardCampaign> {
    return this.http.post<RewardCampaign>(`${environment.offersUrl}/admin/reward/campaign`, request)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getRewardCampaigns(pageRequest: PageRequest, filterParams: FilterField[]): Observable<PageOfRewardCampaign> {
    let searchParams: HttpParams = new HttpParams()
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    filterParams.forEach(filter => {
      if (filter.value) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });

    return this.http.get<PageOfRewardCampaign>(`${environment.offersUrl}/admin/reward/campaign`, {params: searchParams})
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getRewardCampaign(uuid: string): Observable<RewardCampaign> {
    return this.http.get<RewardCampaign>(`${environment.offersUrl}/admin/reward/campaign/${uuid}`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  deleteRewardCampaign(uuid: string): Observable<any> {
    return this.http.delete<any>(`${environment.offersUrl}/admin/reward/campaign/${uuid}`);
  }

  updateRewardCampaign(uuid: string, details: RewardCampaignUpdateRequest): Observable<RewardCampaign> {
    return this.http.put<RewardCampaign>(`${environment.offersUrl}/admin/reward/campaign/${uuid}`, details);
  }

  getPresignedImageUrl(request: RewardCampaignMediaRequest = {mimeType: 'image/png'}): Observable<RewardCampaignMediaResponse> {
    return this.http.post<RewardCampaignMediaResponse>(`${environment.offersUrl}/admin/reward/campaign/media`, request);
  }

  /*
  * Reward Programs
  * */

  getRewardProgram(uuid: string): Observable<RewardProgram> {
    return this.http.get<RewardProgram>(environment.offersUrl + `/admin/reward/programs/${uuid}`)
      .pipe(
        map(rewardProgram => {
          rewardProgram.poolBalance = rewardProgram.poolBalance.replace(',', '');
          rewardProgram.unspentRewards = rewardProgram.unspentRewards.replace(',', '');
          return rewardProgram;
        })
      );
  }

  getRewardPrograms(pageRequest: PageRequest = null,
                    filterParams: FilterField[] = [],
                    unpaged = false): Observable<PageOfRewardProgram> {
    let searchParams: HttpParams = new HttpParams()

    if (unpaged) {
      searchParams = searchParams.append('unpaged', 'true');
    } else {
      if (pageRequest) {
        searchParams = searchParams.append('page', `${pageRequest.page}`);
        searchParams = searchParams.append('size', `${pageRequest.size}`);
      }
    }
    filterParams.forEach(filter => {
      if (filter.value) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });
    return this.http.get<PageOfRewardProgram>(
      environment.offersUrl + '/admin/reward/programs',
      {params: searchParams}
    );
  }

  updateRewardProgram(uuid: string,
                      request: RewardProgramUpdateRequest): Observable<RewardProgram> {
    return this.http.put<RewardProgram>(
      `${environment.offersUrl}/admin/reward/programs/${uuid}`,
      request
    );
  }

  createRewardProgram(details: RewardProgramRequest): Observable<RewardProgram> {
    return this.http.post<RewardProgram>(
      `${environment.offersUrl}/admin/reward/programs`,
      details);
  }

  // getRewardProgramLog(brandId: string, pageRequest: PageRequest): Observable<PageResponse<RewardProgramLog>> {
  //   let searchParams: HttpParams = new HttpParams()
  //     .append('page', `${pageRequest.page}`)
  //     .append('size', `${pageRequest.size}`)
  //     .append('brandId', brandId);
  //   return this.http.get<PageResponse<RewardProgramLog>>(environment.offersUrl + `/admin/reward/programs/log`, {params: searchParams});
  // }

  // getRewardProgramBalances(brandId: string) {
  //   return this.http.get<any>(environment.offersUrl + `/admin/reward/programs/balance?brandId=${brandId}`);
  // }

  // getRewardProgramBalance(campaignId: string) {
  //   return this.http.get<any>(environment.offersUrl + `/admin/reward/programs/balance?rewardCampaignId=${campaignId}`);
  //   // .pipe(first(balance => balance.rewardCampaignId === campaignId))
  // }

  getRewardProgramRewardActivity(brandId: string,
                                 pageRequest: PageRequest,
                                 filterParams: FilterField[],
                                 unpaged: boolean = false): Observable<PageOfRewardActivityTotalsView> {
    let searchParams: HttpParams = new HttpParams()
      .append('brandId', brandId)
      .append('sort', 'transactionDate,desc');

    if (unpaged) {
      searchParams = searchParams.append('unpaged', 'true');
    } else {
      searchParams = searchParams.append('page', `${pageRequest.page}`)
      searchParams = searchParams.append('size', `${pageRequest.size}`)
    }
    filterParams.forEach(filter => {
      if (filter.value) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });

    return this.http.get<PageOfRewardActivityTotalsView>(
      environment.offersUrl + `/admin/activity/reward`,
      {params: searchParams}
    );
  }

  getRewardProgramActivityTotals(brandId: string,
                                 filterParams: FilterField[]): Observable<RewardActivityMultiTotals> {
    let searchParams: HttpParams = new HttpParams()
      .append('brandId', brandId);

    filterParams.forEach(filter => {
      if (filter.value) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });
    return this.http.get<RewardActivityMultiTotals>(
      environment.offersUrl + `/admin/activity/reward/totals`,
      {params: searchParams}
    ).pipe(
      map(totals => {
        totals.totals = totals?.totals?.map(total => {
          if (total?.amount < 0) {
            total.amount = total.amount * -1
            return total;
          }
          return total;
        });
        return totals;
      })
    );
  }

  saveRewardProgramFunds(uuid: string,
                         rewardProgramFundsRequest: RewardProgramFundsRequest): Observable<RewardProgramFundLogResponse> {
    return this.http.post<RewardProgramFundLogResponse>(
      `${environment.offersUrl}/admin/reward/programs/${uuid}/funds`,
      rewardProgramFundsRequest
    );
  }

  getRewardProgramFunds(uuid: string,
                        pageRequest: PageRequest,
                        unpaged: boolean = false): Observable<PageOfRewardProgramFundLogResponse> {
    let searchParams: HttpParams = new HttpParams()

    if (unpaged) {
      searchParams = searchParams.append('unpaged', 'true');
    } else {
      searchParams = searchParams.append('page', `${pageRequest.page}`);
      searchParams = searchParams.append('size', `${pageRequest.size}`);
    }
    return this.http.get<PageOfRewardProgramFundLogResponse>(
      `${environment.offersUrl}/admin/reward/programs/${uuid}/funds`,
      {params: searchParams}
    );
  }

  deleteRewardProgram(uuid: string): Observable<any> {
    return this.http.delete<any>(`${environment.offersUrl}/admin/reward/programs/${uuid}`);
  }

  getRewardProgramTransactions(uuid: string,
                               pageRequest: PageRequest = null,
                               filterParams: FilterField[] = [],
                               unpaged = false): Observable<PageOfQualifyingTransaction> {
    let searchParams: HttpParams = new HttpParams();

    if (unpaged) {
      searchParams = searchParams.append('unpaged', 'true');
    } else {
      searchParams = searchParams.append('page', `${pageRequest.page}`)
      searchParams = searchParams.append('size', `${pageRequest.size}`)
    }
    filterParams.forEach(filter => {
      if (filter.value) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });

    return this.http.get<RewardsQualifyingTransaction>(
      environment.offersUrl + `/admin/reward/programs/${uuid}/transactions`,
      {params: searchParams}
    ).pipe(
      map(qualifyingRewardTransaction => qualifyingRewardTransaction.transactions)
    );
  }

  getRewardProgramSubscribers(uuid: string,
                              pageRequest: PageRequest = null,
                              filterParams: FilterField[] = [],
                              unpaged = false): Observable<PageOfRewardsSubscriber> {
    let searchParams: HttpParams = new HttpParams();

    if (unpaged) {
      searchParams = searchParams.append('unpaged', 'true');
    } else {
      searchParams = searchParams.append('page', `${pageRequest.page}`)
      searchParams = searchParams.append('size', `${pageRequest.size}`)
    }
    filterParams.forEach(filter => {
      if (filter.value) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });

    return this.http.get<PageOfRewardsSubscriber>(
      environment.offersUrl + `/admin/reward/programs/${uuid}/subscribers`,
      {params: searchParams}
    );
  }

  createRewardTransaction(campaignUuid: string,
                          userUuid: string,
                          eligibleSpendTotal: string,
                          checkoutId: string): Observable<RewardTransaction> {
    const body = {
      campaignUuid,
      userUuid,
      eligibleSpendTotal,
      checkoutId
    };

    return this.http.post<RewardTransaction>(
      environment.offersUrl + '/webhook/reward',
      body
    ).pipe(
      catchError(
        this.handleError
      )
    );
  }

  /*
  * Transactions
  * */

  getTransaction(uuid: string): Observable<Transaction> {
    return this.http.get<Transaction>(`${environment.offersUrl}/admin/transactions/${uuid}`);
  }

  /*
  * Program
  * */

  getClosedLoopPrograms(brandId: string,
                        pageRequest: PageRequest,
                        unpaged = false): Observable<PageOfClosedLoopProgram> {
    let searchParams: HttpParams = new HttpParams();

    if (brandId) {
      searchParams = searchParams.append('brandId', brandId);
    }

    if (unpaged) {
      searchParams = searchParams.append('unpaged', 'true');
    } else {
      searchParams = searchParams.append('page', `${pageRequest.page}`)
      searchParams = searchParams.append('size', `${pageRequest.size}`)
    }

    return this.http.get<PageOfClosedLoopProgram>(
      environment.offersUrl + `/admin/closedloop/programs`,
      {params: searchParams}
    ).pipe(
      catchError(
        this.handleError
      )
    );
  }

  getClosedLoopProgram(uuid: string): Observable<ClosedLoopProgram> {
    return this.http.get<ClosedLoopProgram>(environment.offersUrl + `/admin/closedloop/programs/${uuid}`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  createClosedLoopProgram(request: ClosedLoopProgramRequest): Observable<ClosedLoopProgram> {
    return this.http.post<ClosedLoopProgram>(
      environment.offersUrl + `/admin/closedloop/programs/`,
      request
    ).pipe(
      catchError(
        this.handleError
      )
    );
  }

  updateClosedLoopProgram(uuid: string, request: ClosedLoopProgramUpdateRequest): Observable<ClosedLoopProgram> {
    return this.http.put<ClosedLoopProgram>(
      environment.offersUrl + `/admin/closedloop/programs/${uuid}`,
      request
    ).pipe(
      catchError(
        this.handleError
      )
    );
  }

  deleteClosedLoopProgram(uuid: string) {
    return this.http.delete(environment.offersUrl + `/admin/closedloop/programs/${uuid}`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }
}
