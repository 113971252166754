/**
 * offers-api
 *
 * Contact: kkozak@ovloop.com, mhernandez@ovoop.com, bpico@ovloop.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LocationStatus } from './locationStatus';


export interface MerchantRequest { 
    alias?: string;
    avatar?: string;
    channel?: MerchantRequest.ChannelEnum;
    confirmDelete?: boolean;
    endpoint?: string;
    entBrandId?: string;
    entOrgId?: string;
    fullImage?: string;
    giftOption?: boolean;
    image?: string;
    keywords?: Array<string>;
    locations?: Array<LocationStatus>;
    merchantIdentifier?: string;
    name?: string;
    owner?: string;
    previewText?: string;
    riskLevel?: number;
    type?: MerchantRequest.TypeEnum;
    verified?: boolean;
}
export namespace MerchantRequest {
    export type ChannelEnum = 'IN_STORE' | 'OL_STO' | 'ONLINE' | 'UNKNOWN';
    export const ChannelEnum = {
        InStore: 'IN_STORE' as ChannelEnum,
        OlSto: 'OL_STO' as ChannelEnum,
        Online: 'ONLINE' as ChannelEnum,
        Unknown: 'UNKNOWN' as ChannelEnum
    };
    export type TypeEnum = 'BRAND' | 'MID';
    export const TypeEnum = {
        Brand: 'BRAND' as TypeEnum,
        Mid: 'MID' as TypeEnum
    };
}


