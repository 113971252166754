/**
 * offers-api
 *
 * Contact: kkozak@ovloop.com, mhernandez@ovoop.com, bpico@ovloop.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BrandProgram } from './brandProgram';
import { Integration } from './integration';
import { BrandLocation } from './brandLocation';
import { BrandProgramMid } from './brandProgramMid';
import { User } from './user';
import { Keyword } from './keyword';
import { VerifiedMerchantLocation } from './verifiedMerchantLocation';
import { RewardProgram } from './rewardProgram';
import { Campaign } from './campaign';
import { RewardCampaign } from './rewardCampaign';
import { ClosedLoopProgram } from './closedLoopProgram';
import { GiftCardMid } from './giftCardMid';
import { Location } from './location';


export interface Merchant { 
    activeBrandProgram?: BrandProgram;
    activeRewardCampaigns?: Array<RewardCampaign>;
    alias?: string;
    avatar?: string;
    brandLocations?: Array<BrandLocation>;
    brandProgram?: BrandProgram;
    brandProgramMids?: Array<BrandProgramMid>;
    campaigns?: Array<Campaign>;
    channel?: Merchant.ChannelEnum;
    closedLoopProgram?: ClosedLoopProgram;
    closedLoopPrograms?: Array<ClosedLoopProgram>;
    created?: string;
    endpoint?: string;
    entBrandId?: string;
    entOrgId?: string;
    fullImage?: string;
    giftCards?: Array<GiftCardMid>;
    giftOption?: boolean;
    id?: number;
    image?: string;
    integrations?: Array<Integration>;
    keywords?: Array<Keyword>;
    merchantIdentifier?: string;
    name?: string;
    nearbyLocations?: Array<Location>;
    owner?: User;
    ownerSerializer?: boolean;
    previewText?: string;
    removed?: boolean;
    rewardCampaign?: RewardCampaign;
    rewardCampaigns?: Array<RewardCampaign>;
    rewardProgram?: RewardProgram;
    rewardPrograms?: Array<RewardProgram>;
    riskLevel?: number;
    type?: Merchant.TypeEnum;
    updated?: string;
    users?: Array<User>;
    uuid?: string;
    verified?: boolean;
    verifiedLocations?: Array<VerifiedMerchantLocation>;
}
export namespace Merchant {
    export type ChannelEnum = 'IN_STORE' | 'OL_STO' | 'ONLINE' | 'UNKNOWN';
    export const ChannelEnum = {
        InStore: 'IN_STORE' as ChannelEnum,
        OlSto: 'OL_STO' as ChannelEnum,
        Online: 'ONLINE' as ChannelEnum,
        Unknown: 'UNKNOWN' as ChannelEnum
    };
    export type TypeEnum = 'BRAND' | 'MID';
    export const TypeEnum = {
        Brand: 'BRAND' as TypeEnum,
        Mid: 'MID' as TypeEnum
    };
}


