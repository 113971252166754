import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {OffersService} from '../../../services/offers-api.service';
import {ConfirmationService} from 'primeng/api';
import {Location} from '@angular/common';
import {ErrorDisplayService} from '../../../services/error-display.service';
import {AuthService} from '../../../services/auth.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AuthRoles} from '../../../domain/auth/auth-roles';
import {RewardCampaign} from '../../../domain/generated/offers/model/rewardCampaign';
import {RewardCampaignUpdateRequest} from '../../../domain/generated/offers/model/rewardCampaignUpdateRequest';
import {UpdateMidStatus} from '../../../domain/generated/offers/model/updateMidStatus';

@Component({
  selector: 'app-page-reward-campaign-details',
  templateUrl: './page-reward-campaign-details.component.html',
  styleUrls: ['./page-reward-campaign-details.component.css']
})
export class PageRewardCampaignDetailsComponent implements OnInit {
  public readonly midStatus = UpdateMidStatus.StatusEnum;

  // Data
  uuid: string;
  obj: RewardCampaign;

  // Visual
  loading = true;
  activeLoading = false;
  discoverableLoading = false;
  ignoreTerminalIdLoading = false;
  showEditTitle = false;
  showImageUploadModal = false;
  deleteDisable = false;
  showAddMidForm = false;

  midForm: FormGroup;

  constructor(private route: ActivatedRoute,
              private location: Location,
              private offersService: OffersService,
              private confirmationService: ConfirmationService,
              private errorDisplayService: ErrorDisplayService,
              private authService: AuthService,
              private fb: FormBuilder) {
  }


  ngOnInit() {
    this.route.params.subscribe(params => {
      this.loadData(params['uuid']);
    });

    this.initializeMidForm();

    this.authService.hasRole([AuthRoles.ADMIN, AuthRoles.SUPPORT]).subscribe(results => this.deleteDisable = !results);
  }

  // Initial API Calls
  loadData(uuid: string) {
    this.uuid = uuid;
    this.loading = true;
    this.offersService.getRewardCampaign(uuid).subscribe(result => {
      console.log('Reward campaign fetched.');
      this.obj = result;
      this.loading = false;
    });
  }

  handleActiveChange(val) {
    this.activeLoading = true;
    const req = {
      active: val.checked
    };
    this.offersService.updateRewardCampaign(this.uuid, req as RewardCampaignUpdateRequest).subscribe(result => {
      this.activeLoading = false;
      this.obj = result;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Active change', error);
      this.obj.active = !val.checked;
      this.activeLoading = false;
    })
  }

  handleDiscoverableChange(val) {
    this.discoverableLoading = true;
    const req = {
      discoverable: val.checked
    };
    this.offersService.updateRewardCampaign(this.uuid, req as RewardCampaignUpdateRequest).subscribe(result => {
      this.discoverableLoading = false;
      this.obj = result;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Discoverable change', error);
      this.obj.discoverable = !val.checked;
      this.discoverableLoading = false;
    })
  }

  handleIgnoreTerminalIdChange(val) {
    this.ignoreTerminalIdLoading = true;
    const req = {
      ignoreTerminalId: val.checked
    }
    this.offersService.updateRewardCampaign(this.uuid, req as RewardCampaignUpdateRequest).subscribe(result => {
      this.ignoreTerminalIdLoading = false;
      this.obj = result;
    }, error => {
      this.errorDisplayService.displayErrorResponse('IgnoreTerminalId change', error);
      this.obj.ignoreTerminalId = !val.checked;
      this.ignoreTerminalIdLoading = false;
    })
  }

  updateTitle(title) {
    this.doUpdate({title});
    this.showEditTitle = false;
  }

  updatePendingDuration(pendingDurationHours) {
    this.doUpdate({pendingDurationHours})
  }

  updateSpendingStartDatetime(spendingStartDatetime) {
    this.doUpdate({spendingStartDatetime});
  }

  updateSpendingEndDatetime(spendingEndDatetime) {
    this.doUpdate({spendingEndDatetime});
  }

  doUpdate(details) {
    this.loading = true;
    this.offersService.updateRewardCampaign(this.uuid, details).subscribe(result => {
      this.obj = result;
      this.loading = false;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Reward Campaign Update', error);
      this.loading = false;
    });
  }

  confirmDelete() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this reward campaign?',
      header: 'Delete Confirmation',
      icon: 'fa fa-trash',
      accept: () => {
        this.offersService.deleteRewardCampaign(this.uuid).subscribe(() => {
          this.obj.removed = true;
          this.location.back();
        })
      },
      reject: () => {
        console.log('Cancelled deletion')
      }
    });
  }

  saveDescription(newDescription: string) {
    this.doUpdate({description: newDescription});
  }

  handleImageUpload(url) {
    this.doUpdate({imageUrl: url});
    this.showImageUploadModal = false;
  }

  /*
  * Mids
  * */

  initializeMidForm() {
    this.midForm = this.fb.group({
      mids: ['']
    });
  }

  clearMidForm() {
    this.midForm.patchValue({
      mids: ['']
    })
  }

  saveMids() {
    this.loading = true;
    const request: RewardCampaignUpdateRequest = {};
    request.mids = this.midForm.get('mids').value.map(uuid => ({id: uuid, status: UpdateMidStatus.StatusEnum.Active}));
    request.verifiedLocationIds = this.obj.verifiedLocationIds.filter(vli =>
      request.mids.find(mid => mid.id === vli.mid)
    ).map(vli => ({id: vli.uuid, status: UpdateMidStatus.StatusEnum.Active}));
    this.offersService.updateRewardCampaign(this.uuid, request).subscribe(result => {
      this.obj = result;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Add Mid', error);
    }).add(() => {
      this.loading = false;
      this.showAddMidForm = false;
    })
  }

  updateMidStatus(uuid: string, status: UpdateMidStatus.StatusEnum) {
    this.loading = true;
    const req: RewardCampaignUpdateRequest = {
      mids: [{id: uuid, status}],
      verifiedLocationIds: [{id: this.obj.verifiedLocationIds.find(vli => vli.mid === uuid).uuid, status}]
    }
    this.offersService.updateRewardCampaign(this.uuid, req).subscribe(result => {
      this.obj = result;
    }, error => {
      this.errorDisplayService.displayErrorResponse(`Reward Campaign Mid Update - ${status}`, error);
    }).add(() => {
      this.loading = false;
    });
  }

  getMidOptions() {
    return this.obj?.rewardProgram?.mids?.filter(rpm =>
      !this.obj?.mids?.find(rcm =>
        (rcm.mid.uuid === rpm.mid.uuid) && this.obj?.verifiedLocationIds.find(vml => vml.mid === rpm.mid.uuid)
      )
    ).map(rpm => rpm.mid);
  }
}
