/**
 * offers-api
 *
 * Contact: kkozak@ovloop.com, mhernandez@ovoop.com, bpico@ovloop.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BrandLocation } from './brandLocation';
import { Merchant } from './merchant';
import { RewardCampaign } from './rewardCampaign';
import { Location } from './location';


export interface VerifiedMerchantLocation { 
    alias?: string;
    brandLocation?: BrandLocation;
    channel?: VerifiedMerchantLocation.ChannelEnum;
    created?: string;
    endpoint?: string;
    id?: number;
    location?: Location;
    merchant?: Merchant;
    removed?: boolean;
    rewardCampaigns?: Array<RewardCampaign>;
    terminalId?: string;
    updated?: string;
    uuid?: string;
}
export namespace VerifiedMerchantLocation {
    export type ChannelEnum = 'IN_STORE' | 'OL_STO' | 'ONLINE' | 'UNKNOWN';
    export const ChannelEnum = {
        InStore: 'IN_STORE' as ChannelEnum,
        OlSto: 'OL_STO' as ChannelEnum,
        Online: 'ONLINE' as ChannelEnum,
        Unknown: 'UNKNOWN' as ChannelEnum
    };
}


