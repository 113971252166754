import { Component, OnInit } from '@angular/core';
import {SherlockService} from '../../services/sherlock.service';
import {HapiService} from '../../services/hapi.service';
import {OffersService} from '../../services/offers-api.service';
import { BuildInfo } from 'app/domain/common/buildinfo';
import {WalletService} from '../../services/wallet.service';
import {CVaultService} from '../../services/cvault.service';
import {environment} from '../../../environments/environment';
import {ConsumersApiService} from '../../services/consumers-api.service';
import {PaymentsService} from '../../services/payments.service';
import {UidService} from '../../services/uid.service';
import {BillingService} from '../../services/billing.service';
import {BuildInfoService} from "../../services/build-info.service";

@Component({
  selector: 'app-page-deployments',
  templateUrl: './page-deployments.component.html',
  styleUrls: ['./page-deployments.component.css']
})
export class PageDeploymentsComponent implements OnInit {
  projects: AppStatus[] = [];
  cols: any[];

  constructor(private sherlockService: SherlockService,
              private hapiService: HapiService,
              private offersService: OffersService,
              private walletService: WalletService,
	            private cVaultService: CVaultService,
              private paymentsService: PaymentsService,
              private billingService: BillingService,
              private uidService: UidService,
              private consumersApiService: ConsumersApiService,
              private buildInfoService: BuildInfoService) {

  }

  ngOnInit(): void {
    const sherlockStatus = new AppStatus('Sherlock API', environment.sherlockUrl);
    this.projects.push(sherlockStatus);
    this.sherlockService.getBuildInfo().subscribe(result => {
      sherlockStatus.buildInfo = result;
      sherlockStatus.loading = false;
    }, error => {
      sherlockStatus.loading = false;
    });

    const hapiStatus = new AppStatus('Houston API', environment.hapiUrl);
    this.projects.push(hapiStatus);
    this.hapiService.getBuildInfo().subscribe(result => {
      hapiStatus.buildInfo = result;
      hapiStatus.loading = false;
    }, error => {
      hapiStatus.loading = false;
    });

    const offersStatus = new AppStatus('Offers API', environment.offersUrl);
    this.projects.push(offersStatus);
    this.offersService.getBuildInfo().subscribe(result => {
      offersStatus.buildInfo = result;
      offersStatus.loading = false;
    }, error => {
      offersStatus.loading = false;
    });

    const walletStatus = new AppStatus('Wallet API', environment.walletUrl);
    this.projects.push(walletStatus);
    this.walletService.getBuildInfo().subscribe(result => {
      walletStatus.buildInfo = result;
      walletStatus.loading = false;
    }, error => {
      walletStatus.loading = false;
    });

    const paymentsStatus = new AppStatus('Payments API', environment.paymentsUrl);
    this.projects.push(paymentsStatus);
    this.paymentsService.getBuildInfo().subscribe(result => {
      paymentsStatus.buildInfo = result;
      paymentsStatus.loading = false;
    }, error => {
      paymentsStatus.loading = false;
    });

    const billingStatus = new AppStatus('Billing API', environment.billingUrl);
    this.projects.push(billingStatus);
    this.billingService.getBuildInfo().subscribe(result => {
      billingStatus.buildInfo = result;
      billingStatus.loading = false;
    }, error => {
      billingStatus.loading = false;
    });

    const uidStatus = new AppStatus('UID API', environment.uidUrl);
    this.projects.push(uidStatus);
    this.uidService.getBuildInfo().subscribe(result => {
      uidStatus.buildInfo = result;
      uidStatus.loading = false;
    }, error => {
      uidStatus.loading = false;
    });

    const cVaultStatus = new AppStatus('Consumer Vault API', environment.cVaultUrl);
    this.projects.push(cVaultStatus);
    this.cVaultService.getBuildInfo().subscribe(result => {
      cVaultStatus.buildInfo = result;
      cVaultStatus.loading = false;
    }, error => {
      cVaultStatus.loading = false;
    });

    // const stapiStatus = new AppStatus('ST API', environment.stapiUrl);
    // this.projects.push(stapiStatus);
    // this.hapiService.getStapiBuildInfo().subscribe(result => {
    //   stapiStatus.buildInfo = result;
    //   stapiStatus.loading = false;
    // }, error => {
    //   stapiStatus.loading = false;
    // });

    // const consumersStatus = new AppStatus('Consumers API', environment.consumersApiUrl);
    // this.projects.push(consumersStatus);
    // this.consumersApiService.getBuildInfo().subscribe(result => {
    //   consumersStatus.buildInfo = result;
    //   consumersStatus.loading = false;
    // }, error => {
    //   consumersStatus.loading = false;
    // });

    const bopStatus = new AppStatus('BackOffice Portal', environment.envName);
    this.projects.push(bopStatus);
    bopStatus.buildInfo =  this.buildInfoService.getBuildInfo();
    bopStatus.loading = false;

    this.cols = [
      { field: 'application', header: 'Application' },
      { field: 'url', header: 'URL' },
      // { field: 'project', header: 'Project' },
      { field: 'version', header: 'Version' },
      { field: 'build', header: 'Built' },
      { field: 'revision', header: 'Revision' },
      { field: 'status', header: 'Status' }
    ];
  }

}

class AppStatus {
  name: String;
  url: String;
  buildInfo: BuildInfo;
  loading = true;


  constructor(name: String, url: String) {
    this.name = name;
    this.url = url;
  }
}
