import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OffersService} from '../../../services/offers-api.service';
import {MessageService} from 'primeng/api';
import {Campaign} from '../../../domain/generated/offers/model/campaign';
import {TimeUnit} from '../../../domain/forms/timeunit';
import {Weekday} from '../../../domain/forms/weekdays';
import {Month} from '../../../domain/forms/months';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest} from 'rxjs';
import {CustomValidators} from '../../common/custom-validators';

@Component({
  selector: 'app-form-campaign',
  templateUrl: './form-campaign.component.html',
  styleUrls: ['./form-campaign.component.css']
})
export class FormCampaignComponent implements OnInit {

  showAdmin = false;
  form: FormGroup;
  campaign: Campaign = {};
  isLoading = true;
  timeUnit = TimeUnit;
  monthConverter = Month;
  weekdays: Weekday[];
  months: Month[];
  minDate: Date;
  maxDate: Date;

  constructor(private offersService: OffersService,
              private messageService: MessageService,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router) {

    this.weekdays = Weekday.listAll();
    this.months = Month.listAll();
    this.showAdmin = true;

    this.minDate = new Date();
    this.minDate.setMonth(0);
    this.minDate.setDate(1);
    this.minDate.setFullYear(2017);
    this.maxDate = new Date();
    this.maxDate.setMonth(0);
    this.maxDate.setDate(31);
    this.maxDate.setFullYear(2017);

    combineLatest(
      this.route.paramMap,
      this.route.queryParamMap,
      (params, queryParams) => ({
          merchantUuid: queryParams.get('merchant'),
          campaignId: params.get('id')
        })).subscribe(params => {
      console.log(params);
      const campaignId = params['campaignId'];
      const merchantUuid = params['merchantUuid'];
      // if (campaignId && campaignId.length > 0) {
      //
      //   offersService.getCampaign(Number(campaignId)).subscribe(campaign => {
      //     this.campaign = campaign;
      //     this.initializeForm();
      //     this.isLoading = false;
      //   }, error => {
      //     this.isLoading = false;
      //     // this.router.navigateByUrl('/home');
      //   });
      // } else
      if (!this.campaign.merchant) {
        this.offersService.getMerchant(merchantUuid).subscribe(merchant => {
          this.campaign.merchant = merchant;
          if (!merchant.verified) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error creating campaign',
              detail: 'Merchant not verified.'
            });
            this.router.navigateByUrl('/offers/merchants');
            // this.router.navigateByUrl('/offers/merchants/' + merchant.uuid);
          }
          this.initializeForm();
          this.isLoading = false;
        }, error => {
          this.isLoading = false;
          console.error('Can\'t find merchant.');
          // this.router.navigateByUrl('/home');
        });
      }
    });
  }

  ngOnInit() {
  }

  initializeForm() {
    // Includes all fields expected to be utilized by UI
    this.form = this.fb.group({
      id: [this.campaign.id],
      merchant: this.fb.group({uuid: [this.campaign.merchant ? this.campaign.merchant.uuid : null]}),
      percentBased: [{value: (this.campaign as any).percentBased, disabled: this.notAllowed(false, true)}],
      discount: [(this.campaign as any).discount, [Validators.required, Validators.min(this.numberIfHasBeenCollected((this.campaign as any).discount, 0))]],
      description: [this.campaign.description, [Validators.required, Validators.maxLength(200)]],
      durationTime: [(this.campaign as any).durationTime, [Validators.required, Validators.min(this.campaign.hasBeenCollected === true && (!this.showAdmin) ? Number((this.campaign as any).durationTime) : -1)]],
      durationUnit: [{value: (this.campaign as any).durationUnit.toString(), disabled: this.campaign.hasBeenCollected}],
      saveUpTo: [this.campaign.saveUpTo, [Validators.min(this.numberIfHasBeenCollected(this.campaign.saveUpTo, 0))]],
      whenSpend: [this.campaign.whenSpend, [Validators.min(0), Validators.max(this.numberIfHasBeenCollected(this.campaign.whenSpend, 1000000))]],
      purchases: [{value: this.campaign.purchases, disabled: this.notAllowed(false, true)}, [Validators.min(0)]],
      purchasesAtLeast: [{value: this.campaign.purchasesAtLeast, disabled: this.notAllowed(false, true)}, [Validators.min(0)]],
      visits: [{value: this.campaign.visits, disabled: this.notAllowed(false, true)}],
      totalSpend: [{value: this.campaign.totalSpend, disabled: this.notAllowed(false, true)}, [Validators.min(0)]],
      // 'loyaltyPercentOff': [this.campaign.loyaltyPercentOff, [Validators.min(0)]],
      // this is wells fargo deal thing - maybe don't support it unless we have to
      // 'loyaltyDollarOff': [this.campaign.loyaltyDollarOff, [Validators.min(this.numberIfHasBeenCollected(this.campaign.loyaltyDollarOff, 0))]],
      recollect: [this.campaign.recollect],
      kycRequired: [this.campaign.kycRequired],
      onKyc: [{value: this.campaign.onKyc, disabled: this.notAllowed(false, true)}],
      feedCollectLimit: [this.campaign.feedCollectLimit],
      collectLimit: [this.campaign.collectLimit],
      feedCollectionLimited: [(this.campaign as any).feedCollectionLimited],
      collectionLimited: [(this.campaign as any).collectionLimited],
      isLoyaltyOffer: [{value: (this.campaign as any).isLoyaltyOffer, disabled: this.notAllowed(false, true)}],
      isTimeBasedOffer: [{value: (this.campaign as any).isTimeBasedOffer, disabled: ((this.campaign as any).isTimeBasedOffer !== true && this.campaign.hasBeenCollected)}],

      weekdays: [{value: this.campaign.weekdays, disabled: this.blockAddAfterCollect(this.campaign.weekdays, true)},
        this.campaign.hasBeenCollected ? [new CustomValidators().cannotRemoveValidities(this.campaign.weekdays)] : []],
      months: [{value: this.campaign.months, disabled: this.blockAddAfterCollect(this.campaign.months)},
        this.campaign.hasBeenCollected ? [new CustomValidators().cannotRemoveValidities(this.campaign.months)] : []],
      monthdaysDates: [{value: (this.campaign as any).monthdaysDates, disabled: this.blockAddAfterCollect((this.campaign as any).monthdaysDates)},
        this.campaign.hasBeenCollected ? [new CustomValidators().cannotRemoveValidities((this.campaign as any).monthdaysDates)] : []],

      startTimeMapped: [{value: (this.campaign as any).startTimeMapped, disabled: this.blockAddAfterCollect((this.campaign as any).startTimeMapped)},
        this.campaign.hasBeenCollected && (this.campaign as any).startTimeMapped !== null ? [new CustomValidators().maxTime((this.campaign as any).startTimeMapped)] : []],
      endTimeMapped: [{value: (this.campaign as any).endTimeMapped, disabled: this.blockAddAfterCollect((this.campaign as any).endTimeMapped)},
        this.campaign.hasBeenCollected && (this.campaign as any).endTimeMapped !== null ? [new CustomValidators().minTime((this.campaign as any).endTimeMapped)] : []],

      timeZone: [{value: this.campaign.timeZone, disabled: this.notAllowed(false, true)}],
      offerUrl: [this.campaign.offerUrl]
    }, {
      // validator: Validators.compose([new CustomValidators().cityRequiresState])
    });
  }

  numberIfHasBeenCollected(existingVal, defaultVal) {
    if (!this.campaign.hasBeenCollected ) {
      return defaultVal;
    } else if (existingVal === null) {
      return 0;
    } else {
      return Number(existingVal);
    }
  }

  blockAddAfterCollect(value, log = false) {
    if (log) {
 console.log(value);
}
    if (value === null) {
      if (log) {
 console.log(1);
}
      return this.campaign.hasBeenCollected;
    } else if (Array.isArray(value)) {
      if (log) {
 console.log(2);
}
      return this.campaign.hasBeenCollected && value.length === 0;
    } else {
      return false;
    }
  }

  notAllowed(allowAdmin: boolean, mustBeUncollected: boolean) {
    if (allowAdmin && this.showAdmin) {
      return true;
    } else if (this.campaign.hasBeenCollected) {
      return mustBeUncollected;
    } else {
      return false;
    }
  }

  save() {
    if (this.form.pristine) {
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Nothing to save'});
      return;
    }
    this.campaign = this.form.getRawValue() as Campaign;

    this.isLoading = true;
    this.offersService.save(this.campaign).subscribe(result => {
      this.campaign = result;
      this.isLoading = false;
      this.initializeForm();
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Campaign Saved'});
    }, error => {
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Failed to save campaign.'});
      console.error(error);
    });
  }

  remove() {
    this.isLoading = true;
    this.offersService.deleteRewardCampaign(this.campaign.uuid).subscribe(result => {
      this.isLoading = false;
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Campaign Deactivated'});
      this.router.navigateByUrl('/merchant/' + this.campaign.merchant.uuid);
    }, error => {
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Failed to deactivate campaign.'});
      this.isLoading = false;
    });
  }

  cancel() {
    this.router.navigateByUrl(`/merchant/${this.campaign.merchant.uuid}`);
  }

  //
  // form: FormGroup;
  // countries: Country[] = [];
  // states: Region[] = [];
  //
  // isLoading = false;
  //
  //
  // private _campaign: Campaign = null;
  //
  // @Input() set campaign(value: Campaign) {
  //
  //   this._campaign = value;
  //   if (this.form) {
  //     if (value) {
  //       this.form.patchValue({
  //         'description': value?.description,
  //         // 'owner': value?.owner,
  //         // 'verified': value?.verified,
  //         // 'giftOption': value?.giftOption,
  //         // 'identifier': value?.identifier,
  //         // 'city': value?.city,
  //         // 'state': value?.state,
  //       });
  //       // this.form.controls['orgId'].disable();
  //       // this.form.controls['paymentGatewayCid'].disable();
  //     } else {
  //       // this.form.controls['orgId'].enable();
  //       // this.form.controls['paymentGatewayCid'].enable();
  //       this.form.reset();
  //     }
  //   }
  // }
  //
  // get campaign(): Campaign { return this._campaign; }
  //
  // @Output() updated = new EventEmitter<OffersMerchant>();
  //
  // constructor(private fb: FormBuilder,
  //             private localDataService: LocalDataService,
  //             private offersService: OffersService,
  //             private messageService: MessageService) { }
  //
  // ngOnInit() {
  //   this.fetchCountries();
  //   this.initializeForm();
  // }
  //
  //
  // fetchCountries() {
  //   this.localDataService.getCountriesAndStates().subscribe(items => {
  //     this.countries = items;
  //     this.states = this.countries.map(country => country.regions).reduce((a, b) => {
  //       return a.concat(b);
  //     });
  //   });
  // }
  //
  //
  // countryChanged() {
  //   if (this.form.value.country === 'All') {
  //     this.states = this.countries.map(country => country.regions).reduce((a, b) => {
  //       return a.concat(b);
  //     });
  //   } else {
  //     this.states = this.countries.find(item => {
  //       return item.abbreviation === this.form.value.country;
  //     }).regions;
  //   }
  //
  //   // Reset value if no longer valid
  //   const formState = this.form.value.state;
  //   if (formState && formState.toUpperCase() !== 'ALL') {
  //     if (!this.states.find(region => region.abbreviation === formState)) {
  //       this.form.get('state').setValue('All');
  //     }
  //   }
  // }
  //
  // filterNonAlpha(newValue) {
  //   return newValue.key.match(/[a-zA-Z\s]+/) !== null;
  // }
  //
  // initializeForm() {
  //   this.form = this.fb.group({
  //     'description': [this.campaign?.description, [Validators.required, Validators.maxLength(255)]],
  //     // 'owner': [this.campaign?.owner, [Validators.maxLength(255)]],
  //     // 'verified': [{value: this.campaign?.verified}],
  //     // 'premier': [{value: this.merchant?.premier}],
  //     // 'giftOption': [this.campaign?.giftOption],
  //     // 'identifier': [{value: this.campaign?.identifier, disabled: false}, [Validators.required, Validators.maxLength(19)]],
  //     // 'city': [{value: this.campaign?.city, disabled: false}, [Validators.maxLength(16)]],
  //     // 'state': [{value: this.campaign?.state ? this.merchant.state : 'All', disabled: false}],
  //     // 'country': [{value: 'All', disabled: false}],
  //     // 'shortUrl': [this.merchant.shortUrl],
  //     // 'vanityUrl': [null],
  //   });
  // }
  //
  //
  //
  // save() {
  //   this.isLoading = true;
  //   let apiRequest = this.campaign ? this.updateExisting() : this.saveNew();
  //
  //   apiRequest.subscribe(result => {
  //     this.isLoading = false;
  //     this.updated.emit(result);
  //   }, error => {
  //     this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to save campaign. ${error.message}`});
  //     this.isLoading = false;
  //   })
  // }
  //
  // saveNew() {
  //   return this.offersService.createCampaign(
  //     this.form.value.name,
  //     this.form.value.owner,
  //     this.form.value.identifier,
  //     this.form.value.city,
  //     this.form.value.state,
  //     this.form.value.giftOption,
  //     this.form.value.verified,
  //   );
  // }
  //
  // updateExisting() {
  //   return this.offersService.updateCampaign(
  //     this.campaign.uuid,
  //     this.form.value.name,
  //     this.form.value.owner,
  //     this.form.value.identifier,
  //     this.form.value.city,
  //     this.form.value.state,
  //     this.form.value.giftOption,
  //     this.form.value.verified,
  //   );
  // }
}
