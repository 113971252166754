
<ng-container *ngIf="!consumerId">
  <form [formGroup]="form" class="row mb-2" (ngSubmit)="doSearch()" (keydown.enter)="$event.preventDefault()" novalidate>

    <div class="form-group col-xs-12 col-sm-6 col-md-6" >
      <label class="text-uppercase" for="startDateTime">Start Date</label>
      <p-calendar class="pull-right p-fluid" inputStyleClass="form-control" id="startDateTime" showTime="true" formControlName="startDateTime"  ></p-calendar>
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="endDateTime">End Date</label>
      <p-calendar class="pull-right p-fluid" inputStyleClass="form-control" id="endDateTime" showTime="true" formControlName="endDateTime" ></p-calendar>
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" [ngClass]="{'text-muted':form.controls['types'].value.length == 0}" >Activity Types</label>
      <div class="form-control" style="height: auto;">
        <ng-container  *ngFor="let type of types">
          <p-checkbox [ngClass]="{'text-muted':form.controls['types'].value.length == 0}"  name="types" label="{{type}}" value="{{type}}" [formControl]="form.controls['types']">{{type}}</p-checkbox><br>
        </ng-container>
      </div>
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="userUuid">User UUID</label>
      <input class="form-control" id="userUuid" type="text" formControlName="userUuid">
    </div>

    <div class="col-12 text-right">
      <button class="btn btn-secondary btn-sm mr-2" (click)="clearForm()">Clear</button>
      <button type="submit" class="btn btn-primary btn-sm" [disabled]="!form.valid || loading" name="searchButton" >
        <span>Search</span>
      </button>
    </div>
  </form>
</ng-container>

<div class="row">

  <p-table [value]="activities"  [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]" [responsive]="true"
           [loading]="loading"   (onLazyLoad)="loadActivitiesLazy($event)"   [totalRecords]="totalRecords" [lazy]="true"  [autoLayout]="true" class="col-12"
           sortField="created" [sortOrder]="-1"  stateStorage="session" stateKey="activies-session" styleClass="p-datatable-gridlines" #dt>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-activity>
      <tr>
        <td>
          <span class="d-none d-md-block">{{activity.created | date:'medium'}}</span>
          <span class="d-block d-md-none">{{activity.created | date:'shortDate'}}</span>
        </td>
        <td>
          <ng-container *ngIf="activity.offersUser">
            <i (click)="showUserPreview($event,activity.offersUser, userOverlay)" class="fa fa-sm fa-search mr-1"  *ngIf="!consumerId"></i>
            <a class="d-none d-lg-inline-block" routerLink="/user/{{activity.offersUser.uuid}}" routerLinkActive="active">{{activity.offersUser.uuid}}</a>
            <a class="d-inline-block d-lg-none" routerLink="/user/{{activity.offersUser.uuid}}" routerLinkActive="active">View</a>
          </ng-container>
        </td>
        <td>
          {{ activity?.title}}
        </td>
        <td>
          <i [ngStyle]="{'color': iconColor(activity)}" class="fa {{fontawesomeIconClass(activity)}} fa-lg"></i>
          {{activity.activityType}}
        </td>
        <td>
          {{activity?.rewardActivityType}}
        </td>
        <td>
          {{activity?.amount | currency:'USD'}}
        </td>
        <td>
          <button class="btn btn-primary btn-sm" (click)="selectActivity(activity)">View</button>
          <i class="fa fa-location-arrow text-primary" *ngIf="activity.latitude"></i>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer">
      <tr><td colspan="7">
        <div class="text-center" *ngIf="totalRecords != null"> {{totalRecords}} results </div>
      </td> </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog modal="modal" header="Detail View" [draggable]="false" [resizable]="false" [(visible)]="selectedActivity" [dismissableMask]="true" [style]="{'width':'600px'}">
  <!--TODO fix map for spend activities-->
  <!--<p-gmap *ngIf="selectedActivity?.latitude" [options]="mapOptions" [style]="{'width':'100%','height':'320px'}" [overlays]="overlays" ></p-gmap>-->
  <!--<hr>-->
  <pre>{{selectedActivity | json}}</pre>
</p-dialog>


<p-overlayPanel #userOverlay  [showCloseIcon]="true" [style]="{'width':'600px'}">
  <app-consumer-card [consumerId]="selectedUser"></app-consumer-card>
</p-overlayPanel>
