import {BaseObject} from '../common/base-object';
import {FicentiveTransactionType} from './ficentive-transaction-type';

export class FicentiveTransaction extends BaseObject {
  amount: string;
  balance: string;
  description: string;
  id: string;
  location: string;
  note: string;
  status: string;
  time: string;
  title: string;
  transactionType: string;
  raw: any;
  type: string;

  fontawesomeIconClass(): string {
    switch (this.type) {
      case FicentiveTransactionType.PURCHASE:
      case FicentiveTransactionType.REDEEMED_REWARD:
        return 'fa-shopping-cart';
      case FicentiveTransactionType.CC_CREDIT:
      case FicentiveTransactionType.CC_RETURN:
      case FicentiveTransactionType.CC_MARK:
        return 'fa-cc-stripe';
      case FicentiveTransactionType.ADMIN_LOAD_UNLOAD:
      case FicentiveTransactionType.CARD_TRANSFER:
      case FicentiveTransactionType.VOID:
      case FicentiveTransactionType.TOP_UP:
        return 'fa-plus-square-o';
      case FicentiveTransactionType.BALANCE_ADJUSTMENT:
        return 'fa-edit';
      case FicentiveTransactionType.REFUND:
      case FicentiveTransactionType.FEE_CHARGE:
      default:
        return 'fa-minus-square-o';
    }
  }

  iconColor(): string {
    switch (this.type) {
      case FicentiveTransactionType.PURCHASE:
      case FicentiveTransactionType.REDEEMED_REWARD:
      case FicentiveTransactionType.CC_CREDIT:
      case FicentiveTransactionType.FEE_CHARGE:
        return 'green';
      case FicentiveTransactionType.ADMIN_LOAD_UNLOAD:
      case FicentiveTransactionType.CC_RETURN:
        return 'red';
      case FicentiveTransactionType.TOP_UP:
      case FicentiveTransactionType.REFUND:
      case FicentiveTransactionType.CARD_TRANSFER:
      case FicentiveTransactionType.VOID:
      case FicentiveTransactionType.CC_MARK:
      case FicentiveTransactionType.BALANCE_ADJUSTMENT:
      default:
        return 'black';
    }
  }

}
