import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FileUpload} from 'primeng/fileupload';
import {OffersService} from '../../../services/offers-api.service';
import {base64ToFile, ImageCroppedEvent} from 'ngx-image-cropper';
import {EnterpriseApiService} from "../../../services/enterprise-api.service";
import {OrganizationImageType} from "../../../domain/enterprise/organization/organization-image-type";
import {ErrorDisplayService} from "../../../services/error-display.service";

@Component({
  selector: 'app-image-upload-dialog',
  templateUrl: './image-upload-dialog.component.html',
  styleUrls: ['./image-upload-dialog.component.css']
})
export class ImageUploadDialogComponent implements OnInit {

  @Output()
  imageUploaded = new EventEmitter<string>()

  @Output()
  cancelled = new EventEmitter<void>()

  @Input()
  domain: 'offers'|'enterprise'

  /*
  * Enterprise Image Upload
  * */
  @Input()
  orgId: string;
  fileName: string;
  imageTypes = Object.values(OrganizationImageType).filter(type => typeof type === 'string');
  enterpriseImageType: OrganizationImageType = this.imageTypes[0];

  @Input()
  type: 'logo'|'icon'|'cover'|'campaign';

  @ViewChild('imageInput')
  imageInput: FileUpload = null;

  @Input()
  visible = false;

  loading = false;
  uploading = false;
  cropImgPreview: any = '';
  srcImageFile: File = null;

  aspectRatio: number;
  maxWidth: number;
  maxHeight: number;

  constructor(private offersService: OffersService,
              private enterpriseService: EnterpriseApiService,
              private errorDisplayService: ErrorDisplayService) { }

  ngOnInit(): void {
    this.adjustMaxSizes(this.domain === 'offers' ? this.type : this.enterpriseImageType);
  }

  adjustMaxSizes(type) {
    switch (type) {
      case 'icon':
        this.aspectRatio = 1
        this.maxWidth = 618
        this.maxHeight = 188
        break;
      case 'logo':
      case 'cover':
      default:
        this.aspectRatio = 309/94
        this.maxWidth = 618
        this.maxHeight = 188
        break;
    }
  }

  imageUpload() {
    const croppedImageFile = base64ToFile(this.cropImgPreview);
    if (this.domain === 'offers') {
      this.uploadToOffers(croppedImageFile);
    } else if (this.domain === 'enterprise') {
      this.uploadToEnterprise(croppedImageFile);
    }
  }

  uploadToOffers(croppedImageFile) {
    this.uploading = true;
    this.offersService.getPresignedImageUrl().subscribe(url => {
      this.offersService.uploadImageToPresignedUrl(url.uploadUrl, croppedImageFile).subscribe(
        uploadResult => {
          this.imageUploaded.emit(url.downloadUrl);
          this.uploading = false;
          this.clear();
        }, error => {
          this.uploading = false;
          this.errorDisplayService.displayErrorResponse('Get Presigned Image Url', error);
        }
      )
    }, error => {
      this.uploading = false;
      this.errorDisplayService.displayErrorResponse('Get Presigned Image Url', error);
    });
  }

  uploadToEnterprise(croppedImageFile) {
    this.uploading = true;
    this.enterpriseService.uploadImage(this.orgId, {
      type: this.enterpriseImageType,
      fileName: this.formatFileName(this.fileName, croppedImageFile.type),
      selectedFile: croppedImageFile}
    ).subscribe(result => {
      this.imageUploaded.emit();
      this.uploading = false;
      this.clear();
    }, error => {
      this.uploading = false;
      this.errorDisplayService.displayErrorResponse('Image Dialog Upload', error);
    })
  }

  clear() {
    this.srcImageFile = null;
    this.cropImgPreview = null;
    try {
      this.imageInput.clear();
    } catch (e) {}
    if (this.domain === 'enterprise') {
      this.fileName = null;
      this.enterpriseImageType = this.imageTypes[0];
    }
  }

  onFileUploadChange(event: any) {
    this.srcImageFile = event.files[0]
  }

  cropImg(e: ImageCroppedEvent) {
    this.cropImgPreview = e.base64;
  }

  imgLoad() {
    // display cropper tool
  }

  initCropper() {
    // init cropper
  }

  imgFailed() {
    // error msg
  }

  cancel() {
    this.clear();
    this.cancelled.emit();
  }

  formatFileName(fileName, imageType) {
    if (fileName.split('.').length > 1) {
      return fileName;
    } else {
      return `${fileName}.${imageType.split('/')[1]}`
    }
  }
}
