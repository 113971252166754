import {Component, OnInit, ViewChild} from '@angular/core';
import {Table} from 'primeng/table';
import {LazyLoadEvent, MessageService} from 'primeng/api';
import {PageRequest} from '../../../domain/common/paging';
import {OffersService} from '../../../services/offers-api.service';
import {UnspentFundsResponse} from '../../../domain/generated/offers/model/unspentFundsResponse';

@Component({
  selector: 'app-page-unspent-balances',
  templateUrl: './page-unspent-balances.component.html',
  styleUrls: ['./page-unspent-balances.component.css']
})
export class PageUnspentBalancesComponent implements OnInit {


  unspentBalances: UnspentFundsResponse[] = [];
  loading = true;
  cols: any[];
  formVisible = false;
  selectedItem: UnspentFundsResponse = null;

  totalRecords: number;

  @ViewChild('dt') datatable: Table;

  constructor(private offersService: OffersService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.cols = [
      {field: 'balance', header: 'Balance'},
      {field: 'ownerId', header: 'Owner'},
      {field: 'closeDate', header: 'Close Date'},
      {field: 'status', header: 'Status'},
    ];
  }

  getUnspentBalances(pageRequest: PageRequest) {
    this.loading = true;
    this.offersService.getUnspentBalances(pageRequest).subscribe(result => {
      this.totalRecords = result.totalElements;
      this.unspentBalances = result.content;
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  loadDataLazy(event: LazyLoadEvent) {
    const page: number = event.first / event.rows;
    const pageRequest: PageRequest = {page, size: event.rows};
    this.getUnspentBalances(pageRequest);
  }


}
