import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {OffersService} from '../../../services/offers-api.service';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-page-create-reward-program',
  templateUrl: './page-create-reward-program.component.html',
  styleUrls: ['./page-create-reward-program.component.css']
})
export class PageCreateRewardProgramComponent implements OnInit {
  isLoading = false;
  form: FormGroup;
  brandOptions: {title: string, value: string}[];
  brandId: string = null;

  constructor(private fb: FormBuilder,
              private offersService: OffersService,
              private messageService: MessageService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {
    this.initializeForm();
    this.route.queryParams.subscribe(params => {
      const brandId = params['brandId']
      if (brandId) {
        this.brandId = brandId;
        this.form.patchValue({
          brandId: this.brandId
        });
      } else {
        this.loadBrands();
      }
    });
  }

  loadBrands() {
    this.isLoading = true;
    this.offersService.getMerchants(null, {type: 'BRAND'}, true).subscribe(results => {
      this.brandOptions = results.content.filter(obj => !obj.rewardProgram).map(obj => ({
          title: obj.name,
          value: obj.uuid
        }));
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to fetch free brands.\n${error.message}`});
    });
  }

  initializeForm() {
    this.form = this.fb.group({
      title: ['', [Validators.maxLength(255), Validators.required]],
      brandId: ['', [Validators.maxLength(255), Validators.required]],
      avatar: [null],
      description: [``],
      discoverable: [false],
      expirationPeriod: [365],
      image: [null],
      maxRewardAmountPrint: [''],
      mids: [[]],
      poolAmount: [''],
      previewText: [''],
    });
  }

  save() {
    this.offersService.createRewardProgram(this.form.value).subscribe(result => {
      this.router.navigateByUrl(`offers/rewardprogram/${result.uuid}`);
    }, error => {
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to create reward program.\n${error.message}`});
      this.router.navigateByUrl(`offers/rewardprograms`);
    });
  }

  handleBrandChange(event) {
    this.form.patchValue({
      brandId: event.value
    })
  }
}
