// Build information, automatically generated by `ng-info`
const build = {
    version: "2.27.0",
    timestamp: "Fri Feb 14 2025 17:46:51 GMT+0000 (Coordinated Universal Time)",
    message: null,
    git: {
        user: null,
        branch: "develop",
        hash: "ce77e4",
        fullHash: "ce77e420d68993d0b9ced448dd3f56004b1ecd2a"
    }
};

export default build;