import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {AuthRoles} from '../../../domain/auth/auth-roles';
import {OffersService} from '../../../services/offers-api.service';

@Component({
  selector: 'app-funds-card',
  templateUrl: './funds-card.component.html',
  styleUrls: ['./funds-card.component.css']
})
export class FundsCardComponent implements OnInit {

  @Input()
  consumerId: string;

  formVisible = false;
  loading = false;

  funds: any;

  fundDisable = true;

  constructor(private offersService: OffersService,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.getFunds();
    this.authService.hasRole([AuthRoles.ADMIN, AuthRoles.SUPPORT]).subscribe(result => this.fundDisable = !result);
  }

  getFunds() {
    this.loading = true;
    this.offersService.getFunds(this.consumerId)
      .subscribe(funds => {
        this.loading = false;
        this.funds = funds;
      }, error => {
        this.loading = false;
        if (error.code === 10040) {
          this.funds = {};
          return;
        } else {
          this.funds = null;
        }
      });
  }

  addFunds(storeCredit: boolean,
           amountElement: any,
           memoElement: any,
           internalNoteElement: any) {
    const amount = amountElement.value;
    if (storeCredit && amount <= 0) {
      return;
    }

    this.offersService.updateFunds(
      this.consumerId,amount,
      memoElement.value,
      internalNoteElement.value
    ).subscribe(result => {
      this.getFunds();
      amountElement.value = null;
      this.formVisible = false;
    }, error => {
      console.log(error);
    });
  }


}
