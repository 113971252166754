export enum FicentiveTransactionType {
  ADMIN_LOAD_UNLOAD = 'ADMIN_LOAD_UNLOAD',
  REFUND = 'REFUND',
  CARD_TRANSFER = 'CARD_TRANSFER',
  PURCHASE = 'PURCHASE',
  CC_CREDIT = 'CC_CREDIT',
  CC_RETURN = 'CC_RETURN',
  VOID = 'VOID',
  CC_MARK = 'CC_MARK',
  FEE_CHARGE = 'FEE_CHARGE',
  REDEEMED_REWARD = 'REDEEMED_REWARD',
  TOP_UP = 'TOP_UP',
  BALANCE_ADJUSTMENT = 'BALANCE_ADJUSTMENT'
}
