/**
 * offers-api
 *
 * Contact: kkozak@ovloop.com, mhernandez@ovoop.com, bpico@ovloop.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GiftCard } from './giftCard';
import { User } from './user';
import { Transaction } from './transaction';
import { RewardTransaction } from './rewardTransaction';
import { ShareRewardActivityAttribution } from './shareRewardActivityAttribution';


export interface RewardActivity { 
    amount?: string;
    created?: string;
    giftCard?: GiftCard;
    id?: number;
    removed?: boolean;
    reversalRewardActivityId?: number;
    rewardActivityType?: RewardActivity.RewardActivityTypeEnum;
    rewardTransaction?: RewardTransaction;
    shareRewardActivityAttribution?: ShareRewardActivityAttribution;
    startingBalance?: string;
    title?: string;
    transaction?: Transaction;
    updated?: string;
    user?: User;
    uuid?: string;
}
export namespace RewardActivity {
    export type RewardActivityTypeEnum = 'BURNED' | 'CANCELLED' | 'EARNED' | 'EXPIRED' | 'GIFT' | 'RETURNED' | 'REVERSAL' | 'SHARED';
    export const RewardActivityTypeEnum = {
        Burned: 'BURNED' as RewardActivityTypeEnum,
        Cancelled: 'CANCELLED' as RewardActivityTypeEnum,
        Earned: 'EARNED' as RewardActivityTypeEnum,
        Expired: 'EXPIRED' as RewardActivityTypeEnum,
        Gift: 'GIFT' as RewardActivityTypeEnum,
        Returned: 'RETURNED' as RewardActivityTypeEnum,
        Reversal: 'REVERSAL' as RewardActivityTypeEnum,
        Shared: 'SHARED' as RewardActivityTypeEnum
    };
}


