import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OffersService} from '../../../services/offers-api.service';
import {Region} from '../../../domain/forms/region';
import {Country} from '../../../domain/forms/country';
import {ErrorDisplayService} from '../../../services/error-display.service';
import {Merchant} from '../../../domain/generated/offers/model/merchant';
import {MerchantRequest} from '../../../domain/generated/offers/model/merchantRequest';

@Component({
  selector: 'app-form-offers-merchant',
  templateUrl: './form-offers-merchant.component.html',
  styleUrls: ['./form-offers-merchant.component.css']
})
export class FormOffersMerchantComponent implements OnInit {
  @Output() updated = new EventEmitter<Merchant>();

  form: FormGroup;
  countries: Country[] = [];
  states: Region[] = [];

  channelOptions = Object.values(Merchant.ChannelEnum).filter(type => typeof type === 'string');
  isLoading = false;

  private _merchant: Merchant = null;

  @Input() set merchant(value: Merchant) {
    this._merchant = value;
    if (this.form) {
      if (value) {
        this.form.patchValue({
          name: value?.name,
          owner: value?.owner,
          verified: value?.verified,
          giftOption: value?.giftOption,
          merchantIdentifier: value?.merchantIdentifier,
          alias: value?.alias,
          endpoint: value?.endpoint,
          channel: value?.channel,
        });
      } else {
        this.form.reset();
      }
    }
  }

  get merchant(): Merchant {
   return this._merchant;
  }

  constructor(private fb: FormBuilder,
              private offersService: OffersService,
              private errorDisplayService: ErrorDisplayService) { }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.form = this.fb.group({
      name: [this.merchant?.name, [Validators.required, Validators.maxLength(255)]],
      owner: [this.merchant?.owner, [Validators.maxLength(255)]],
      verified: [!!this.merchant?.verified],
      giftOption: [!!this.merchant?.giftOption],
      merchantIdentifier: [{value: this.merchant?.merchantIdentifier, disabled: false}, [Validators.required, Validators.minLength(19)]],
      alias: [this.merchant?.alias, [Validators.maxLength(255)]],
      channel: [this.merchant?.channel, [Validators.required]],
      endpoint: [''],
    });
  }

  save() {
    this.isLoading = true;
    const apiRequest = this.merchant ? this.updateExisting() : this.saveNew();
    apiRequest.subscribe(result => {
      this.updated.emit(result);
      this.form.reset();
      this.isLoading = false;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Save Merchant', error);
      this.form.reset();
      this.isLoading = false;
    })
  }

  saveNew() {
    const newMid: MerchantRequest = {
      name: this.form.get('name').value,
      owner: this.form.get('owner').value,
      merchantIdentifier: this.form.get('merchantIdentifier').value,
      alias: this.form.get('alias').value,
      giftOption: this.form.get('giftOption').value,
      verified: this.form.get('verified').value,
      endpoint: this.form.get('endpoint').value,
      channel: this.form.get('channel').value,
    };
    return this.offersService.createMid(newMid);
  }

  isNew(property, value) {
    return this.merchant[property] !== value ? value : null;
  }

  updateExisting() {
    const request: MerchantRequest = {
      name: this.isNew('name', this.form.get('name').value),
      owner: this.isNew('owner', this.form.get('owner').value),
      merchantIdentifier: this.isNew('merchantIdentifier', this.form.get('merchantIdentifier').value),
      alias: this.isNew('alias', this.form.get('alias').value),
      giftOption: this.isNew('giftOption', this.form.get('giftOption').value),
      verified: this.isNew('verified', this.form.get('verified').value),
      endpoint: this.isNew('endpoint', this.form.get('endpoint').value),
      channel: this.isNew('channel', this.form.get('channel').value),
    };
    return this.offersService.updateMerchant(
      this.merchant.uuid,
      request
    );
  }
}
