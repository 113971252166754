/**
 * offers-api
 *
 * Contact: kkozak@ovloop.com, mhernandez@ovoop.com, bpico@ovloop.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LocationStatus { 
    alias?: string;
    channel?: LocationStatus.ChannelEnum;
    endpoint?: string;
    id?: string;
    latitude?: number;
    longitude?: number;
    status?: LocationStatus.StatusEnum;
    terminalId?: string;
}
export namespace LocationStatus {
    export type ChannelEnum = 'IN_STORE' | 'OL_STO' | 'ONLINE' | 'UNKNOWN';
    export const ChannelEnum = {
        InStore: 'IN_STORE' as ChannelEnum,
        OlSto: 'OL_STO' as ChannelEnum,
        Online: 'ONLINE' as ChannelEnum,
        Unknown: 'UNKNOWN' as ChannelEnum
    };
    export type StatusEnum = 'ACTIVE' | 'REMOVED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Removed: 'REMOVED' as StatusEnum
    };
}


