import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentsService } from '../../services/payments.service';
import { BillingAddress } from '../../domain/payments/billing-address';
import { MessageService } from 'primeng/api';
import { ErrorDisplayService } from '../../services/error-display.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import * as luhn from 'fast-luhn';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-form-offers-merchant-payment',
  templateUrl: './form-offers-merchant-payment.component.html',
  styleUrls: ['./form-offers-merchant-payment.component.css']
})

export class FormOffersMerchantPaymentComponent implements OnInit {
  @Input() formType: string;
  @Input() tenderTypes: object[];
  @Input() tenderType: string;
  @Input() entBrandId: string;

  uuid: string;
  loading = false;
  formSending = false;
  luhnValid: boolean;
  formBank: FormGroup;
  formCard: FormGroup;
  formData: FormGroup;
  billingAddress: BillingAddress;
  paymentCard: object;
  bankAccount: object;
  activeInstrument: any;

  constructor(private fb: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private paymentsService: PaymentsService,
              private messageService: MessageService,
              private errorDisplayService: ErrorDisplayService) { }

  ngOnInit() {
    this.loadData()
  }

  loadData () {
    this.formType = 'bank';
    this.tenderTypes = [
      { name: "Savings", value: "SAVINGS" },
      { name: "Checking", value: "CHECKING" }
    ]
    this.tenderType = 'CHECKING';

    this.getRouteParams();
    this.initializeForm();
    this.getActiveInstruments();
  }

  getRouteParams() {
    this.route.params.subscribe(params => {
      this.uuid = params['uuid'];
    });
  }

  initializeForm() {
    this.formBank = this.fb.group({
      'accountName': ['', [Validators.required]],
      'accountNumber': ['', [Validators.required]],
      'routingNumber': ['', [Validators.required]],
      'tenderType': [''],
    });

    this.formCard = this.fb.group({
      'pan': ['', [Validators.required]],
      'nameOnCard': ['', [Validators.required]],
      'expYear': ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      'expMonth': ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      'cvv': ['', [Validators.required]],
      'address1': [''],
      'address2': [''],
      'city': [''],
      'state': [''],
      'postalCode': [''],
      'country': [''],
    });
  }

  save() {
    if (this.formType === 'bank') {
      this.formData = this.formBank
      this.saveInstrumentBank()
    }

    if (this.formType === 'card') {
      this.formData = this.formCard
      this.setBillingAddressData()
      this.saveInstrumentCard()
    }
  }

  saveInstrumentBank() {
    this.bankAccount = {
      accountName: this.formData.value.accountName,
      accountNumber: this.formData.value.accountNumber,
      routingNumber: this.formData.value.routingNumber,
      tenderType: this.tenderType,
    }

    // We must delete an active instrument before editing or creating a new one
    this.formSending = true;

    if (this.activeInstrument?.id) {
      this.deleteCurrentInstrument().subscribe( result => {
        console.log('Instrument removed')
        this.addMerchantInstrumentBank()
        return
      }, err => {
        this.errorDisplayService.displayErrorResponse('Failed to delete instrument', err);
      })
    } else {
      this.addMerchantInstrumentBank()
    }
  }

  saveInstrumentCard() {
    this.paymentCard = {
      pan: this.formData.value.pan,
      cvv: this.formData.value.cvv,
      expMonth: this.formData.value.expMonth,
      expYear: this.formData.value.expYear,
      nameOnCard: this.formData.value.nameOnCard,
    }

    // We must delete an active instrument before editing or creating a new one
    this.formSending = true;

    if (this.activeInstrument?.id) {
      this.deleteCurrentInstrument().subscribe( result => {
        console.log('Instrument removed')
        this.addMerchantInstrumentCard()
        return
      }, err => {
        this.errorDisplayService.displayErrorResponse('Failed to delete instrument', err);
      })
    } else {
      this.addMerchantInstrumentCard()
    }
  }

  addMerchantInstrumentBank() {
    this.paymentsService.addMerchantInstrumentBank(this.entBrandId, this.billingAddress, this.bankAccount).subscribe( result => {
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'New bank account was added.'});
      this.formSending = false;
      this.goBack()
      console.log('A bank account instrument created')
    }, err => {
      this.formSending = false;
      this.errorDisplayService.displayErrorResponse('Failed to create a bank account instrument', err);
    });
  }

  addMerchantInstrumentCard() {
    this.paymentsService.addMerchantInstrumentCard(this.entBrandId, this.billingAddress, this.paymentCard).subscribe( result => {
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'New card was added.'});
      this.formSending = false;
      this.goBack()
      console.log('A payment card instrument created')
    }, err => {
      this.formSending = false;
      this.errorDisplayService.displayErrorResponse('Failed to create a payment card instrument', err);
    });
  }

  deleteCurrentInstrument() {
    return this.paymentsService.deleteMerchantInstrument(this.activeInstrument.id);
  }

  setBillingAddressData() {
    this.billingAddress = new BillingAddress(this.formData.value.address1, this.formData.value.address2, this.formData.value.city, this.formData.value.state, this.formData.value.country, this.formData.value.postalCode);

    this.billingAddress.address1 = this.billingAddress.address1 ? this.billingAddress.address1 : null;
    this.billingAddress.address2 = this.billingAddress.address2 ? this.billingAddress.address2 : null;
    this.billingAddress.city = this.billingAddress.city ? this.billingAddress.city : null;
    this.billingAddress.state = this.billingAddress.state ? this.billingAddress.state.toUpperCase() : null;
    this.billingAddress.country = this.billingAddress.country ? this.billingAddress.country.toUpperCase() : null;
  }

  getCurrentCards() {
    return this.paymentsService.getAdminMerchantsInstrumentsCards(this.entBrandId);
  }

  getCurrentBanks() {
    return this.paymentsService.getAdminMerchantsInstrumentsBanks(this.entBrandId);
  }

  getActiveInstruments() {
    this.loading = true;
    const bankAccountFetchObservable = this.getCurrentBanks();
    const cardFetchObservable = this.getCurrentCards();

    forkJoin([bankAccountFetchObservable, cardFetchObservable]).subscribe(results => {
      const banksResult = results[0];
      const cardsResult = results[1];

      const activeCard = cardsResult.content.find( card => card.status === 'ACTIVE' );
      const activeBank = banksResult.content.find( bank => bank.status === 'ACTIVE' );

      if (activeCard) {
        this.activeInstrument = activeCard
      }
      else if (activeBank) {
        this.activeInstrument = activeBank
      }
      this.setFormFields();
      this.loading = false;
    }, error => {
      this.loading = false;
      this.errorDisplayService.displayErrorResponse('Fetch: Merchant Bank and Card Instruments', error);
    })
  }

  setFormFields() {
    // Edit mode: show retrieved instrument data in the form
    const type = this.activeInstrument?.paymentCard ? 'card' : 'bank';

    if (type === 'card') {
      this.formType = 'card';
      this.formCard.controls['nameOnCard'].setValue(this.activeInstrument['paymentCard']['nameOnCard'])
      this.formCard.controls['expMonth'].setValue(this.activeInstrument['paymentCard']['expMonth'])
      this.formCard.controls['expYear'].setValue(this.activeInstrument['paymentCard']['expYear'])
      this.formCard.controls['address1'].setValue(this.activeInstrument['paymentCard']['billingAddress']['address1'])
      this.formCard.controls['address2'].setValue(this.activeInstrument['paymentCard']['billingAddress']['address2'])
      this.formCard.controls['city'].setValue(this.activeInstrument['paymentCard']['billingAddress']['city'])
      this.formCard.controls['country'].setValue(this.activeInstrument['paymentCard']['billingAddress']['country'])
      this.formCard.controls['postalCode'].setValue(this.activeInstrument['paymentCard']['billingAddress']['postalCode'])
      this.formCard.controls['state'].setValue(this.activeInstrument['paymentCard']['billingAddress']['state'])
    } else {
      this.formType = 'bank';
      this.formBank.controls['accountName'].setValue(this.activeInstrument?.['bankAccount']['accountName'])
    }
  }

  goBack() {
    this.router.navigateByUrl(`/enterprise/organization/${this.uuid}`)
  }

  // takes the form field value and returns true on valid number
  checkLuhn() {
    let value = this.formCard.value.pan;
    if (!value) { this.luhnValid = null; }

    // // accept only digits, dashes or spaces
    if (/[^0-9-\s]+/.test(value)) { this.luhnValid = false; }
    value = value.replace(/\D/g, '');
    if (value.length < 15 || value.length > 16) {
      this.luhnValid = false;
    }
    this.luhnValid = luhn(value);
  }
}
