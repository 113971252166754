import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {WalletService} from '../../services/wallet.service';
import {MessageService} from 'primeng/api';
import {SherlockAddress} from '../../domain/sherlock/address/sherlockAddress';
import {BillingAddress} from '../../domain/payments/billing-address';
import {SherlockService} from '../../services/sherlock.service';
import * as luhn from 'fast-luhn';

@Component({
  selector: 'app-wallet-card-form',
  templateUrl: './wallet-card-form.component.html',
  styleUrls: ['./wallet-card-form.component.css']
})
export class WalletCardFormComponent implements OnInit {


  @Output()
  cardAdded: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  consumerId: string;

  addresses: SherlockAddress[];
  addressOptions = [];
  selectedAddress: SherlockAddress = null;
  luhnValid: boolean;


  isLoading = true;
  form: FormGroup;


  constructor(private fb: FormBuilder,
              private walletService: WalletService,
              private sherlockService: SherlockService,
              private messageService: MessageService) { }

  ngOnInit() {
    this.initializeForm();
    this.getAddresses();
  }

  getAddresses() {
    this.sherlockService.getSherlockUser(this.consumerId).subscribe(user => {
      this.addresses = user.addresses;
      this.addressOptions = user.addresses.map(address => {
        return {'value': address.uuid, 'name': address.address1}
      });
      this.isLoading = false
    }, error => {
      this.addresses = [];
      this.isLoading = false;
    })
  }

  addressChange(event) {
    const uuid = event.target.value;
    if (uuid === 'new') {
      this.selectedAddress = null;
      // this.form.patchValue({'address1': ''});
      // this.form.patchValue({'address2': ''});
      // this.form.patchValue({'city': ''});
      // this.form.patchValue({'state': ''});
      // this.form.patchValue({'postalCode': ''});
      // this.form.patchValue({'country': ''});
    } else {
      const address = this.addresses.filter(a => {
        return a.uuid === uuid;
      })[0];
      this.selectedAddress = address;
      this.form.patchValue({'address1': address.address1});
      this.form.patchValue({'address2': address.address2});
      this.form.patchValue({'city': address.city});
      this.form.patchValue({'state': address.state});
      this.form.patchValue({'postalCode': address.zip});
      this.form.patchValue({'country': address.country});
    }
  }

  initializeForm() {
    this.form = this.fb.group({
      'pan': [''],
      'cvv': [''],
      'expYear': [''],
      'expMonth': [''],
      'nameOnCard': [''],
      'nickname': [''],
      'selectedAddressUuid': ['new'],
      'address1': [''],
      'address2': [''],
      'city': [''],
      'state': [''],
      'postalCode': [''],
      'country': [''],
    });
  }

  save() {
    this.isLoading = true;

    this.saveNew().subscribe(result => {
      this.isLoading = false;
      this.cardAdded.emit(result);
      this.form.reset();
    }, error => {
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to save card. ${error.message}`});
      this.isLoading = false;
    })
  }

  saveNew() {
    const address = new BillingAddress(this.form.value.address1, this.form.value.address2, this.form.value.city, this.form.value.state, this.form.value.country, this.form.value.postalCode);
    return this.walletService.addCard(
      this.consumerId,
      address,
      this.form.value.pan,
      this.form.value.cvv,
      this.form.value.expMonth,
      this.form.value.expYear,
      this.form.value.nameOnCard,
      this.form.value.nickname
    );
  }

  // takes the form field value and returns true on valid number
  checkLuhn() {

    let value = this.form.value.pan;
    if (!value) { this.luhnValid = null; }
    // // accept only digits, dashes or spaces
    if (/[^0-9-\s]+/.test(value)) { this.luhnValid = false; }
    value = value.replace(/\D/g, '');
    if (value.length < 15 || value.length > 16) {
      this.luhnValid = false;
    }
    this.luhnValid = luhn(value);
  }

}
