/**
 * offers-api
 *
 * Contact: kkozak@ovloop.com, mhernandez@ovoop.com, bpico@ovloop.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ActivityDetails { 
    /**
     * Specify the brand\'s avatar URL within Offer system if exists, otherwise null.
     */
    brandLogo?: string;
    /**
     * Specify the brand name within Offer system if exists, otherwise it will display merchant name sent by the POS.
     */
    brandName?: string;
    /**
     * Card last four digits. Not supported yet
     */
    cardLastFour?: string;
    /**
     * Card type.
     */
    cardType?: ActivityDetails.CardTypeEnum;
    /**
     * Date-time
     */
    created?: number;
    /**
     * Memo
     */
    memo?: string;
    /**
     * Specify the merchant name within Offer system if exists, otherwise null.
     */
    midName?: string;
    /**
     * Paid transaction amount. It may differ from totalAmount since there might be reward redemptions applied to the transaction.
     */
    paidAmount?: string;
    /**
     * Reward Activity Type
     */
    rewardActivityType?: ActivityDetails.RewardActivityTypeEnum;
    /**
     * Reward amount burned, if any.
     */
    rewardAmountBurned?: string;
    /**
     * Reward amount earned, if any.
     */
    rewardAmountEarned?: string;
    /**
     * Reward status, if any.
     */
    rewardIsPending?: boolean;
    /**
     * Source
     */
    source?: string;
    /**
     * Total transaction amount
     */
    totalAmount?: string;
    /**
     * Transaction Status
     */
    txnStatus?: ActivityDetails.TxnStatusEnum;
    /**
     * Date-time
     */
    updated?: number;
}
export namespace ActivityDetails {
    export type CardTypeEnum = 'OV_REWARD';
    export const CardTypeEnum = {
        OvReward: 'OV_REWARD' as CardTypeEnum
    };
    export type RewardActivityTypeEnum = 'BURNED' | 'CANCELLED' | 'EARNED' | 'EXPIRED' | 'GIFT' | 'RETURNED' | 'REVERSAL' | 'SHARED';
    export const RewardActivityTypeEnum = {
        Burned: 'BURNED' as RewardActivityTypeEnum,
        Cancelled: 'CANCELLED' as RewardActivityTypeEnum,
        Earned: 'EARNED' as RewardActivityTypeEnum,
        Expired: 'EXPIRED' as RewardActivityTypeEnum,
        Gift: 'GIFT' as RewardActivityTypeEnum,
        Returned: 'RETURNED' as RewardActivityTypeEnum,
        Reversal: 'REVERSAL' as RewardActivityTypeEnum,
        Shared: 'SHARED' as RewardActivityTypeEnum
    };
    export type TxnStatusEnum = 'CANCELED' | 'DECLINED' | 'FINAL' | 'PENDING';
    export const TxnStatusEnum = {
        Canceled: 'CANCELED' as TxnStatusEnum,
        Declined: 'DECLINED' as TxnStatusEnum,
        Final: 'FINAL' as TxnStatusEnum,
        Pending: 'PENDING' as TxnStatusEnum
    };
}


