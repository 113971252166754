import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {ConsumersApiService} from '../../services/consumers-api.service';
import {Consumer} from '../../domain/consumers/consumer';
import {share} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {AuthRoles} from '../../domain/auth/auth-roles';
import {User} from '../../domain/generated/offers/model/user';

@Component({
  selector: 'app-page-user-detail',
  templateUrl: './page-user-detail.component.html',
  styleUrls: ['./page-user-detail.component.css']
})
export class PageUserDetailComponent implements OnInit {

  // Data
  userUuid: string;
  offersUser: User;
  consumer: Consumer;
  ovCardLastChange: Date = null;

  identityReport: any;

  // Visual
  userIsLoading = true;
  nonAdminDisable = true;
  isExtRoles = false;

  showUpdateName = false;
  tabs: SelectItem[];
  selectedTab;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private consumersApi: ConsumersApiService,
              private confirmationService: ConfirmationService,
              private authService: AuthService) {
    this.tabs = [
      {label: 'Ficentive Transactions', value: 'Ficentive Transactions'},
      {label: 'Topups', value: 'Topups'},
      {label: 'SmartPay Transactions', value: 'SmartPay Transactions'},
      {label: 'ClosedLoop Transactions', value: 'ClosedLoop Transactions'},
      {label: 'Tokenized Transactions', value: 'Tokenized Transactions'},
      {label: 'Gateway Transactions', value: 'Gateway Transactions'},
      {label: 'KYC Attempts', value: 'KYC Attempts'},
      {label: 'Wallet Failure Events', value: 'Wallet Failure Events'},
      // {label: 'Offers', value: 'Offers'},
      // {label: 'Invites', value: 'Invites'},
    ];
    this.selectedTab = this.tabs[0].value;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.loadUserData(params['uuid']);
    });
    this.authService.hasRole([AuthRoles.ADMIN, AuthRoles.SUPPORT]).subscribe(result => this.nonAdminDisable = !result);
    this.authService.hasRole([AuthRoles.EXT_CS_AGENT]).subscribe(result => this.isExtRoles = result);
  }

  // Initial API Calls
  loadUserData(userUuid: string) {
    this.userUuid = userUuid;
    this.getConsumer(userUuid).subscribe(result => {
      console.log('Consumer fetched.');
    });
  }

  getConsumer(userUuid) {
    const newObservable = this.consumersApi.getConsumer(userUuid).pipe(share());
    this.userIsLoading = true;
    newObservable.subscribe(c => {
      this.consumer = c;
      this.userIsLoading = false;
    }, err => {
      console.warn('Could not get consumer');
      this.userIsLoading = false;
      this.consumer = new Consumer();
      this.consumer.id = userUuid;
    });
    return newObservable;
  }

  // Modifying API calls
  deleteUser() {
    console.error('deleteUser not implemented');
  }

  updateName(givenName: string, familyName: string) {
    this.userIsLoading = true;
    this.consumersApi.updateConsumerName(this.userUuid, givenName, familyName).subscribe(result => {
      this.consumer.familyName = familyName;
      this.consumer.givenName = givenName;
      this.showUpdateName = false;
      this.userIsLoading = false;
    }, error => {
      this.showUpdateName = false;
      this.userIsLoading = false;
    })
  }


  confirmDelete() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this user?',
      header: 'Delete Confirmation',
      icon: 'fa fa-trash',
      accept: () => {
        this.deleteUser()
      },
      reject: () => {
        console.log('Cancelled user deletion')
      }
    });
  }

  ovCardChange(lastChange: Date) {
    this.ovCardLastChange = lastChange;
  }

  navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

}
